import { Action } from '@ngrx/store';
import { Order } from '../../../core/models/order.model';
import { CartItem } from '../../../core/models/cart.model';

export enum CartActionTypes {
  ADD_ITEM_TO_CART = '[Cart] Add Item to Cart',
  REMOVE_ITEM_FROM_CART = '[Cart] Remove Item from Cart',

  CONFIRM_ORDER = '[Cart] Confirm Order',
  CONFIRM_ORDER_SUCCESS = '[Cart] Confirm Order Success',
  CONFIRM_ORDER_FAIL = '[Cart] Confirm Order Fail',

  UpdateCartSuccess = '[Cart] Update Cart Success',
  UpdateCartFail = '[Cart] Update Cart Fail',
  GetCartItemSuccess = '[Cart] Get Cart Item Success',
  GetCartItemFail = '[Cart] Get Cart Item Fail',
  RESET_CART = '[Cart] Reset Cart',
  ResetErrorMessage = '[Cart] Reset Error Message',
}

// gc
export class AddItemToCart implements Action {
  readonly type = CartActionTypes.ADD_ITEM_TO_CART;

  constructor(public payload: CartItem) {}
}

// gc
export class RemoveItemFromCart implements Action {
  readonly type = CartActionTypes.REMOVE_ITEM_FROM_CART;

  constructor(public payload: number) {}
}

export class ConfirmOrder implements Action {
  readonly type = CartActionTypes.CONFIRM_ORDER;

  constructor(public payload: Order) {}
}

export class ConfirmOrderSuccess implements Action {
  readonly type = CartActionTypes.CONFIRM_ORDER_SUCCESS;
}

export class ConfirmOrderFail implements Action {
  readonly type = CartActionTypes.CONFIRM_ORDER_FAIL;

  constructor(public payload: any) {}
}

export class ResetCart implements Action {
  readonly type = CartActionTypes.RESET_CART;
}

export class UpdateCartSuccess implements Action {
  readonly type = CartActionTypes.UpdateCartSuccess;
}

export class UpdateCartFail implements Action {
  readonly type = CartActionTypes.UpdateCartFail;

  constructor(public payload: any) {}
}

export class GetCartItemSuccess implements Action {
  readonly type = CartActionTypes.GetCartItemSuccess;

  constructor(public payload: { carts: CartItem[] }) {}
}

export class GetCartItemFail implements Action {
  readonly type = CartActionTypes.GetCartItemFail;

  constructor(public payload: any) {}
}

export class ResetErrorMessage implements Action {
  readonly type = CartActionTypes.ResetErrorMessage;
}

export type CartActions =
  | AddItemToCart
  | RemoveItemFromCart
  | ConfirmOrder
  | ConfirmOrderSuccess
  | ConfirmOrderFail
  | UpdateCartSuccess
  | UpdateCartFail
  | GetCartItemSuccess
  | GetCartItemFail
  | ResetCart
  | ResetErrorMessage;

