import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Order} from '../../../core/models/order.model';

export interface OrdersState extends EntityState<Order> {
  orders: Array<Order>;
  isLoading: boolean;
}

export const ordersAdapter: EntityAdapter<Order> =
  createEntityAdapter<Order>({
    sortComparer: false
  });

export const ordersInitialState: OrdersState = ordersAdapter.getInitialState(
  {
    orders: [],
    isLoading: false
  }
);
