import { Action } from '@ngrx/store';

export enum ActionTypes {
  OpenSidenav = '[Layout] Open Sidenav',
  CloseSidenav = '[Layout] Close Sidenav',
  GetCartItems = '[Layout] Get Cart Items',
  UpdateDeviceOS = '[Player] Update Device OS',
}

export class OpenSidenav implements Action {
  readonly type = ActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
  readonly type = ActionTypes.CloseSidenav;
}

export class GetCartItems implements Action {
  readonly type = ActionTypes.GetCartItems;
}

export class UpdateDeviceOS implements Action {
  readonly type = ActionTypes.UpdateDeviceOS;
  constructor( public payload: string) {}
}

export type LayoutActions
  = OpenSidenav
  | CloseSidenav
  | GetCartItems
  | UpdateDeviceOS;
