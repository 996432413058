import { CartActions, CartActionTypes } from '../actions';
import { cartAdapter , cartInitialState, CartState, ErrorMessage } from '../states';

export function cartReducer(cartState = cartInitialState, action: CartActions): CartState {
  // console.log(action.type);
  switch (action.type) {

    case CartActionTypes.ADD_ITEM_TO_CART:
      return cartAdapter.addOne(action.payload, cartState);

    case CartActionTypes.REMOVE_ITEM_FROM_CART:
      return cartAdapter.removeOne(action.payload, cartState);

    case CartActionTypes.CONFIRM_ORDER: {
    //  isloading = true;
      break;
    }

    case CartActionTypes.CONFIRM_ORDER_SUCCESS: {
      return cartInitialState;
    }

    case CartActionTypes.CONFIRM_ORDER_FAIL: {
      if (action.payload && action.payload === ErrorMessage.NotLogin) {
       // draft.errorMessage = 'You need to login for confirming order';
      }
     // draft.isloading = false;
      break;
    }

    case CartActionTypes.RESET_CART: {
      return cartAdapter.removeAll(cartState);
    }

    default: {
      return cartState;
    }
  }
}



