// import { OnInit, OnDestroy} from '@angular/core';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {map, switchMap, catchError, withLatestFrom} from 'rxjs/operators';

import * as videosActions from '../../store/actions';
import { VideosState } from '../states';
import { FirestoreDataService } from '../../services/firestore-data.service';
import { IVideoWithMarkCart } from '../../../core/models/video.model';
import { SelectedBook } from '../selectors';
import { SelectedId } from '../selectors'; // use for videos title search - modified: 13/3/20
import { SelectedTag } from '../selectors';
import { SelectedSource } from '../selectors';

@Injectable()
export class VideosEffects {

  constructor(
    private actions$: Actions,
    private videosStore: Store<VideosState>,
    private dataService: FirestoreDataService,
  ) {}

  @Effect()
  LoadRecommendedVideos$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.QUERY_RECOMMENDED),
    switchMap(() => {
      return this.dataService.getRecommended().pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.AddAllRecommended(videos)),
        catchError(error =>
          of(new videosActions.AddAllRecommendedFailure({ error }))
        )
      );
    })
  );

  @Effect()
  LoadRecentVideos$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.QUERY_RECENTS),
    switchMap(() => {
      return this.dataService.getRecents().pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.AddAllRecents(videos)),
        catchError(error =>
          of(new videosActions.AddAllRecentsFailure({ error }))
        )
      );
    })
  );

  @Effect()
  loadAllVideos$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_ALL_VIDEOS_BEGIN),
    switchMap(() => {
      return this.dataService.getAllVideos().pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.LoadAllVideosSuccess(videos)),
        catchError(error =>
          of(new videosActions.LoadAllVideosFailure({ error }))
        )
      );
    })
  );
  // ------------------------------By Title start---------------------------------------------
  // use for videos title search - modified: 13/3/20
  @Effect()
  loadVideosByTitleSearch$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_VIDEOS_BY_ID_BEGIN),
    withLatestFrom(this.videosStore.select(SelectedId)),
    switchMap(([action, videoId]) =>
      this.dataService.getVideoById(videoId).pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.LoadVideoByIdSuccess(videos)),
        catchError(error => of(new videosActions.LoadVideoByIdFailure(error))),
      )
    )
  );

  @Effect()
  UpdateTitlesState$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.UPDATE_TITLES_STATE_BEGIN),
    switchMap(() => {
      return this.dataService.getTitlesForAutocomplete().pipe(
        map(( data ) => new videosActions.UpdateTitlesStateDone(data)),
        catchError(error =>
          of(new videosActions.UpdateTitlesStateFailure({ error }))
        )
      );
    })
  );

  // ------------------------------By Bible start---------------------------------------------
  @Effect()
  loadAllVideosByBible$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_VIDEOS_BY_BIBLE_BEGIN),
    switchMap(() => {
      return this.dataService.getAllVideosByBible().pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.LoadVideosByBibleSuccess(videos)),
        catchError(error =>
          of(new videosActions.LoadVideosByBibleFailure({ error }))
        )
      );
    })
  );

  @Effect()
  loadVideosByBook$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_VIDEOS_BY_BOOK_BEGIN),
    withLatestFrom(this.videosStore.select(SelectedBook)),
    switchMap(([action, book]) =>
      this.dataService.getVideosByBook(book).pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.LoadVideosByBookSuccess(videos)),
        catchError(error => of(new videosActions.LoadVideosByBookFailure(error))),
      )
    )
  );

  // ------------------------------By TAG start---------------------------------------------
  @Effect() // initial videos load
  loadAllByTagsSort$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_ALL_BY_TAG_SORT_BEGIN),
    switchMap(() => {
      return this.dataService.getVideosTagsSort().pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.LoadAllByTagSortSuccess(videos)),
        catchError(error =>
          of(new videosActions.LoadAllByTagSortFailure({ error }))
        )
      );
    })
  );

  @Effect()// load mat-chips
  LoadTags$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_TAGS_BEGIN),
    switchMap(() => {
      return this.dataService.getTags().pipe(
        map(( data ) => new videosActions.LoadTagsDone(data)),
        catchError(error =>
          of(new videosActions.LoadTagsFailure({ error }))
        )
      );
    })
  );

  @Effect()
  loadVideosByTag$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_VIDEOS_BY_TAG_BEGIN),
    withLatestFrom(this.videosStore.select(SelectedTag)),
    switchMap(([action, tag]) =>
      this.dataService.getVideosByTag(tag).pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.LoadVideosByTagSuccess(videos)),
        catchError(error => of(new videosActions.LoadVideosByTagFailure(error))),
      )
    )
  );
  // -------------------------By Tags end--------------------------------

  // By Source
  @Effect()// load mat-chips
  LoadSource$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_SOURCE_BEGIN),
    switchMap(() => {
      return this.dataService.getSource().pipe(
        map(( data ) => new videosActions.LoadSourceDone(data)),
        catchError(error =>
          of(new videosActions.LoadSourceFailure({ error }))
        )
      );
    })
  );

  @Effect()
  loadVideosBySource$ = this.actions$.pipe(
    ofType(videosActions.VideosActionTypes.LOAD_VIDEOS_BY_SOURCE_BEGIN),
    withLatestFrom(this.videosStore.select(SelectedSource)),
    switchMap(([action, source]) =>
      this.dataService.getVideosBySource(source).pipe(
        map((videos: Array<IVideoWithMarkCart>) => new videosActions.LoadVideosBySourceSuccess(videos)),
        catchError(error => of(new videosActions.LoadVideosBySourceFailure(error))),
      )
    )
  );

}
