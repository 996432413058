// from ng-shopping-cart-master

import { Action } from '@ngrx/store';

import { User } from '../../../core/models/user.interface';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutFailure = '[Auth] Logout Failure',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  GetUser = '[Auth] Get User',
  GetUserSuccess = '[Auth] Get User Success',
  GetUserFailure = '[Auth] Get User Failure',
  GetUserRole = '[Auth] Get User Role',
  GetUserRoleSuccess = '[Auth] Get User Role Success',
  GetUserRoleFailure = '[Auth] Get User Role Failure'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public user: User) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LogoutSuccess implements Action {
  readonly type = AuthActionTypes.LogoutSuccess;
}

export class LogoutFailure implements Action {
  readonly type = AuthActionTypes.LogoutFailure;

  constructor(public payload: any) {}
}

export class GetUser implements Action {
  readonly type = AuthActionTypes.GetUser;
}

export class GetUserSuccess implements Action {
  readonly type = AuthActionTypes.GetUserSuccess;

  constructor(public user: User) {}
}

export class GetUserFailure implements Action {
  readonly type = AuthActionTypes.GetUserFailure;

  constructor(public payload: any) {}
}

// gc
export class GetUserRole implements Action {
  readonly type = AuthActionTypes.GetUserRole;
}

// gc
export class GetUserRoleSuccess implements Action {
  readonly type = AuthActionTypes.GetUserRoleSuccess;
  constructor(public payload: true) {}
}

// gc
export class GetUserRoleFailure implements Action {
  readonly type = AuthActionTypes.GetUserRoleFailure;
  constructor(public payload: { error: any }) {}
}

export type AuthActions =
  | Login
  | LoginSuccess
  | LoginFailure
  | Logout
  | LogoutSuccess
  | LogoutFailure
  | GetUser
  | GetUserSuccess
  | GetUserFailure
  | GetUserRole
  | GetUserRoleSuccess;
