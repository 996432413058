import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CartItem } from '../../../core/models/cart.model';
// import {IVideoWithMarkCart} from '../../../core/models/video.model';

/*
export const cartAdapter: EntityAdapter<CartItem> =
  createEntityAdapter<CartItem>({
    selectId: (cart: CartItem) => cart.videoId
  });
*/

/*
export const cartAdapter: EntityAdapter<CartItem> =
  createEntityAdapter<CartItem>({
    selectId: (cart: CartItem) => cart.id
  });
*/

export const cartAdapter: EntityAdapter<CartItem> =
  createEntityAdapter<CartItem>({
    sortComparer:  false
  });

// create new store based on EntityState
export interface CartState extends EntityState<CartItem> {
  isloading: boolean;
  errorMessage: string;
}

export enum ErrorMessage {
  NotLogin = 'Not Login'
}

// set the initial store
export const cartInitialState: CartState = cartAdapter.getInitialState(
  {
    isloading: false,
    errorMessage: '',
  }
);



