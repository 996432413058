import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideosComponent } from './containers';

const videosRoutes: Routes = [
  {
    path: '',
    component: VideosComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        // canActivateChild: [AuthGuard],
        children: [
        //  {path: 'play', component: MatVideoPlayerComponent},
        //  {path: 'recents', component: RecentsListComponent},
        //  {path: '', component: RecommendedListComponent}
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(videosRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class VideosRoutingModule { }

