import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IVideo, IVideoWithMarkCart} from '../../../core/models/video.model';
import {Book} from '../../../core/models/book.model';
import {Title} from '../../../core/models/title.interface';
import {Tag} from '../../../core/models/tag.interface';
import {Source} from '../../../core/models/source.interface';
/*
export function sortByTitle(a: IVideo, b: IVideo): number {
  return a.title.localeCompare(b.title);
}
*/

// export const videosAdapter: EntityAdapter<IVideo> =
export const videosAdapter: EntityAdapter<IVideoWithMarkCart> =
  createEntityAdapter<IVideoWithMarkCart>({
    sortComparer:  false
  });

// create new store based on EntityState
// export interface VideosState extends EntityState<IVideo> {
export interface VideosState extends EntityState<IVideoWithMarkCart> {
  isAddedToCart: boolean;
  isBookmarked: boolean;
  isLoading?: boolean;
  isSearchOpen: boolean;
  isShowDescription: boolean;
  books: Book[];
  source: Source[];
  tags: Tag[];
  titles: Title[];
  selectedBook: string;
  selectedId: number; // use for videos title search - new: 13/3/20
  selectedTag: string;  // use for videos tags search - new: 26/3/20
  selectedSource: string;  // use for videos source search - new: 1/5/20
  // selectedVideo?: IVideo;
  selectedView: string; // use for loading views - modified: 16/3/20
  error?: any;
}

// set the initial store
export const initialState: VideosState = videosAdapter.getInitialState(
  {
    isAddedToCart: false,
    isBookmarked: false,
    isLoading: false,
    isSearchOpen: false,
    isShowDescription: false,
    books: [],
    source: [],
    tags: [],
    titles: [],
    selectedBook: null,
    selectedId: null,  // use for videos title search - new: 13/3/20
    selectedTag: null,  // use for videos tags search - new: 26/3/20
    selectedSource: null,  // use for videos source search - new: 1/5/20
    // selectedVideo: null,
    selectedView: 'recommended',
    error: null,
  });




