// from c/shopping-cart/AngularFirebaseCRUD

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Order, OrderItem } from '../../core/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class CreateOrderService {

  successMsg = 'Data successfully saved.';

  constructor(private firestore: AngularFirestore) {
  }

  // Save cart order navbar to Firestore
  createOrder(data) {
   // console.log(data);
    return new Promise<void>((resolve, reject) => {
      this.firestore.collection<Order>('orders')
        .add(data)
        .then(_ => console.log(this.successMsg))
        .then(res => {}, err => reject(err));
    });
  }

  // Save cartItems to Firestore
  createOrderItems(data) {
    console.log('createOrderItems(data)');
    console.log(data);
    return new Promise<void>((resolve, reject) => {
      this.firestore.collection<OrderItem>('ordersItems')
        .add(data)
        .then(_ => console.log(this.successMsg))
        .then(res => {}, err => reject(err));
    });
  }

}
