import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { CartState } from '../states';
import { CartDataService } from '../../services/cart-data.service';
import { CartService } from '../../services/cart.service';

import {
  CartActionTypes,
 // ConfirmOrder,
 // ConfirmOrderFail,
 // ConfirmOrderSuccess,
 // UpdateCartFail,
 // UpdateCartSuccess
} from '../actions';

/*
import { ErrorMessage } from '../states';
import { getAllCartItems } from '../selectors';
import { selectUser } from '../../../auth/store/selectors/auth.selector';
import * as cartActions from '../../store/actions';
import { User } from '../../../core/models/user.model';
import {IVideoWithMarkCart} from '../../../core/models/video.model';
import * as videosActions from '../../../videos/store/actions';
*/

@Injectable()
export class CartEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CartState>,
    private cartService: CartService,
    private dataService: CartDataService,
    private router: Router,
  ) {}

  @Effect({ dispatch: false })
  confirmOrderSuccess$ = this.actions$.pipe(
    ofType(CartActionTypes.CONFIRM_ORDER_SUCCESS),
    tap(_ => this.router.navigate(['/checkout', 'order-success']))
  );

/*
  @Effect()
  confirmOrder$ = this.actions$.pipe(
    ofType(CartActionTypes.CONFIRM_ORDER),
    withLatestFrom(this.store.select(selectUser)),
    concatMap<[ConfirmOrder, User], ConfirmOrderSuccess | ConfirmOrderFail>(
      ([action, user]: [ConfirmOrder, User]) => {
        if (user) {
          return this.cartService
            .confirmOrder(action.payload, user)
            .pipe(map(res => new ConfirmOrderSuccess()));
        } else {
          return of(new ConfirmOrderFail(ErrorMessage.NotLogin));
        }
      }
    ),
    catchError(err => of(new ConfirmOrderFail(err)))
  );
*/

}
