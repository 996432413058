import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { playerAdapter, PlayerState } from '../states';

const {
  selectIds: _selectItemIds,
  selectEntities: _selectItemEntities,
  selectAll: _selectAllItems,
  selectTotal:  ItemCount,
} = playerAdapter.getSelectors();

export const selectPlayerState = createFeatureSelector<PlayerState>('player');

export const selectVideosIds = createSelector(
  selectPlayerState,
  _selectItemIds
);
export const selectVideosEntities = createSelector(
  selectPlayerState,
  _selectItemEntities
);
export const selectVideosAll = createSelector(
  selectPlayerState,
  _selectAllItems
);
export const selectVideosTotal = createSelector(
  selectPlayerState,
  ItemCount
);

// use by player for playing current video
// ---------------------------------------
export const getSelectedVideo = (state: PlayerState) => state.selectedPlayVideo;

export const SelectedVideo: MemoizedSelector<object, any> = createSelector(
  selectPlayerState,
  getSelectedVideo);
// -----------------------------------------------------------------------------

