import { Injectable, PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
// import { CONFIG } from '../../../environments/environment';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()
export class Utils {

  constructor(
   // @Inject(PLATFORM_ID) private platformId: Object
    ) { }

  regExpEscape(s: string) {
    return String(s).replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1').
      replace(/\x08/g, '\\x08');
  }

  unsubscribe(subs: Subscription[]) {
    subs.forEach(sub => {
      if (sub && sub instanceof Subscription) {
        sub.unsubscribe();
      }
    });
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  dataURItoBlob(dataURI: any) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)]);
  }

  convertIntoDoubleDigit(digit: number) {
    return (digit < 10) ? `0${digit}` : `${digit}`;
  }



  getTimeDifference(gameTimeStamp: number): number {
    const utcDate = new Date(new Date().toUTCString());
    const currentMillis = utcDate.getTime();

    const diff = currentMillis - gameTimeStamp;
    return diff;
  }

}
