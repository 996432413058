import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CartEffects } from './effects';

import { cartReducer } from './reducers';

  @NgModule({
    imports: [
      CommonModule,
      StoreModule.forFeature('cart', cartReducer), // <-----<<< featureName
      EffectsModule.forFeature([CartEffects])
    ],

   providers: [CartEffects]
})

export class CartStoreModule {}


