import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VideosEffects } from './effects';

import { freeVideosReducer } from './reducers';

  @NgModule({
    imports: [
      CommonModule,
      StoreModule.forFeature('freevideos', freeVideosReducer),
      EffectsModule.forFeature([VideosEffects])
    ],

   providers: [VideosEffects]
})

export class FreeVideosStoreModule {}


