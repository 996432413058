import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as videosActions from '../store/actions';
import { VideosState } from '../store/states';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private videosStore: Store<VideosState>,
  ) { }

  // loadTagsIntoState() {
  //  this.videosStore.dispatch(new videosActions.LoadTagsBegin());
  // }

  loadTitlesForAutocomplete() {
    this.videosStore.dispatch(new videosActions.UpdateTitlesStateBegin());
  }

  updateTagSelected(tag) {
    this.videosStore.dispatch(new videosActions.UpdateSelectedTag(tag));
  }

  updateBookSelected(book) {
    this.videosStore.dispatch(new videosActions.UpdateSelectedBook(book));
  }

  LoadVideosByTag() {
    this.videosStore.dispatch(new videosActions.LoadVideosByTagBegin());
  }

  LoadVideosByBook() {
    this.videosStore.dispatch(new videosActions.LoadVideosByBookBegin());
  }

  // modified: title search - 14/3/20
  updateTitleSearchId(videoId) {
    this.videosStore.dispatch(new videosActions.UpdateSelectedId(videoId));
  }

  // modified: title search - 14/3/20
  LoadVideoByTitleSearch(videoId) {
    this.videosStore.dispatch(new videosActions.LoadVideoByIdBegin(videoId));
  }

  // new: source search - 1/5/20
  LoadVideosBySource() {
    this.videosStore.dispatch(new videosActions.LoadVideosBySourceBegin());
  }

}
