import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

// Entity adapter
export const layoutAdapter: EntityAdapter<any> =
  createEntityAdapter<any>({
    // selectId: model => model.id,
    /*
    sortComparer: (a: VideoModel, b: VideoModel): number =>
      b.id.toString().localeCompare(a.id)
     */
    sortComparer: false,
  });

export interface LayoutState extends EntityState<any> {
  showSidenav?: boolean;
  deviceOS?: string;
}

export const InitialState: LayoutState = layoutAdapter.getInitialState(
  {
    showSidenav: false,
    deviceOS: null,
  }
);




