import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Observable, from} from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

import * as OrdersActions from '../../../orders/store/actions/orders.actions';
import { OrdersState } from '../states';
import { Order } from '../../../core/models/order.model';

import { AuthState } from '../../../auth/store/states';
import {selectUser} from '../../../auth/store/selectors';
// import {selectUserEmailId} from '../../../auth/store/selectors';
import { UserService } from '../../../auth/services/user.service';

@Injectable()
export class OrdersEffects {

  constructor(
    private store$: Store<OrdersState>,
    private authStore$: Store<AuthState>,
    private actions$: Actions,
    private userService: UserService,
    private afs: AngularFirestore,
    // private webSocketService: WebsocketService
  ) {}

  @Effect()
  createOrder$: Observable<void> = this.actions$.pipe(
    ofType(OrdersActions.OrdersActionTypes.CREATE_NEW_ORDER),
  //  withLatestFrom(this.authStore$.select(selectUser)),
  //  switchMap(([action, user]) =>
  //       this.ordersService.AddOrders(user.uid).pipe(
   // map((action: actions.Create) => action.Create ),
    switchMap(order => {
     // const ref = this.afs.doc<IOrder>(`orders/${order.id}`);
      const ref = this.afs.doc<Order>(`orders`);
      return from( ref.set(order) );
    }),
    map(() => {
     // return new actions.Success()
    }));

  @Effect({ dispatch: false })
  addOrder$ = this.actions$.pipe(
    ofType(OrdersActions.OrdersActionTypes.ADD_ORDER),
    withLatestFrom(this.authStore$.select(selectUser))
   // tap(([action, user]) =>
    //  this.webSocketService.addOrder({
    //    ...action.payload,
    //    userId: user.uid,
    //  })
    );
  // );


  // Listen for the 'QUERY' action, must be the first effect you trigger
    @Effect()
      query$: Observable<Action> = this.actions$.pipe(
        ofType(OrdersActions.OrdersActionTypes.QUERY_FIRESTORE_BY_USER),
        switchMap(action => {

          const UserEmailId = this.userService.getUserEmailId();

          // tslint:disable-next-line:no-shadowed-variable
          const ref = this.afs.collection<Order>('orders', ref => {
            return ref.where('userId', '==', UserEmailId);
          });

          // const ref = this.afs.collection<VideoModel>('video');
          return ref.snapshotChanges().pipe(
            map(arr => {
              return arr.map( doc => {
                const data = doc.payload.doc.data();
               // console.log(data.orderItems);
                // return { id: doc.payload.doc.id, ...data } as fromReducers.VideoModel ;
                return { id: doc.payload.doc.id, ...data } ;
              });
            }));
        }),

        map(arr => {
          // console.log(arr);
          return new OrdersActions.AddAll(arr);
        }));


  /*
  // tslint:disable-next-line:member-ordering
  @Effect({ dispatch: false })
  addOrder$ = this.actions$.pipe(
    ofType(OrdersActions.ActionTypes.ADD_ORDER),
      withLatestFrom(this.store$.select(store => store.users.idCurrentUser)),
      tap(([action, idCurrentUser]) =>
        this.webSocketService.addOrder({
          ...action.payload,
          userId: idCurrentUser,
        })
      )
    );

  // tslint:disable-next-line:member-ordering
  @Effect({ dispatch: false })
  removeOrder$ = this.actions$.pipe(
    ofType(OrdersActions.ActionTypes.REMOVE_ORDER),
    tap(action => this.webSocketService.removeOrder(action.payload.id)));
  */

  /*
  @Effect()
  getOrders$ = this.actions$.pipe(
    ofType(OrdersActionTypes.GetOrders),
    withLatestFrom(this.store.select(getUser)),
    switchMap(([action, user]) =>
      this.orderService.getOrders(user.uid).pipe(
        map((result: any) => result.orders),
        map((orders: Array<Order>) => new GetOrdersSuccess(orders)),
        catchError(error => of(new GetOrdersFail(error)))
      )
    )
  );
  */

}
