import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// import { authReducers } from './reducers/auth.reducer'; // ng-shopping-cart-master
import { authReducers } from './reducers/auth.reducers'; // trivia
import { AuthEffects } from './effects';

  @NgModule({
    imports: [
      CommonModule,
      StoreModule.forFeature('auth', authReducers), // <-----<<< featureName must be correct. See selectors
      EffectsModule.forFeature([AuthEffects])
    ],

   providers: [AuthEffects]
   // providers: []
})

export class AuthStoreModule {}


