// from ng-shopping-cart-master

import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { AuthState } from '../states/auth.states';
import {getIsAuthenticated} from './auth.selectors';

export const getAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthState: MemoizedSelector<object, AuthState>
  = createFeatureSelector<AuthState>('auth');

export const getLoggedInStatus = createSelector(
  getAuthState,
  state => state.loggedIn
);

export const getUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user
);

export const getLoadingStatus = createSelector(
  getAuthState,
  (state: AuthState) => state.isLoading
);

// gc - MemoizedSelector
export const selectLoggedInStatus: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  state => state.loggedIn);

export const selectLoadingStatus: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  (state: AuthState) => state.isLoading);

export const selectUser: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  state => state.user);

export const selectUserEmailId: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  state => state.user.email);

export const selectUserUid: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  state => state.user.uid);

export const getIsSuperUser = (state: AuthState): boolean => state.isSuperUser;
export const selectIsSuperUser: MemoizedSelector<object, boolean> =
  createSelector(selectAuthState, getIsSuperUser);


