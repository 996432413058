import {PlayerActions, PlayerActionTypes } from '../actions';
import { playerInitialState, PlayerState } from '../states';

export function playerReducer(state = playerInitialState, action: PlayerActions): PlayerState {
  // console.log(action.type);
  switch (action.type) {

    case PlayerActionTypes.SELECTED_PLAY_VIDEO:
      return {
        ...state,
        selectedPlayVideo: action.payload
      };

    case PlayerActionTypes.UNDO_SELECTED_VIDEO:
      return {
        ...state,
        selectedPlayVideo: null
      };

    default: {
      return state;
    }
  }
}
