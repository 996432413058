// from trivia

import { Action } from '@ngrx/store';
import { User } from '../../../core/models/user.model';
// import { User } from '../../../core/models/user.interface';

export enum AuthActionTypes {
  LOGIN_REDIRECT_URL = '[Auth] Login Redirect URL',
  LOGOFF = '[Auth] Log Off',
  LOAD_USER_PROFILE = '[User] Load User Profile',
  Load_USER_PROFILE_SUCCESS = '[User] Load User Profile Success',
  UPDATE_USER = '[Auth] Update User',
  RESET_PASSWORD_NOTIFICATION_LOGS = '[User] Reset Password Notification Logs',
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutFailure = '[Auth] Logout Failure',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  GetUser = '[Auth] Get User',
  GetUserSuccess = '[Auth] Get User Success',
  GetUserFailure = '[Auth] Get User Failure',
  GetUserRole = '[Auth] Get User Role',
  GetUserRoleSuccess = '[Auth] Get User Role Success',
  GetUserRoleFailure = '[Auth] Get User Role Failure'
}

export class Logoff implements Action {
  readonly type = AuthActionTypes.LOGOFF;
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
  constructor(public user: User) {}
}

export class SetLoginRedirectUrl implements Action {
  readonly type = AuthActionTypes.LOGIN_REDIRECT_URL;
  constructor(public payload: string) {}
}

export class SaveResetPasswordNotificationLogs implements Action {
  readonly type = AuthActionTypes.RESET_PASSWORD_NOTIFICATION_LOGS;
  constructor(public payload: string[]) {}
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;
  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LogoutSuccess implements Action {
  readonly type = AuthActionTypes.LogoutSuccess;
}

export class LogoutFailure implements Action {
  readonly type = AuthActionTypes.LogoutFailure;
  constructor(public payload: any) {}
}

export class GetUser implements Action {
  readonly type = AuthActionTypes.GetUser;
}

export class GetUserSuccess implements Action {
  readonly type = AuthActionTypes.GetUserSuccess;
  constructor(public user: User) {}
}

export class GetUserFailure implements Action {
  readonly type = AuthActionTypes.GetUserFailure;
  constructor(public payload: any) {}
}

// gc
export class GetUserRole implements Action {
  readonly type = AuthActionTypes.GetUserRole;
}

// gc
export class GetUserRoleSuccess implements Action {
  readonly type = AuthActionTypes.GetUserRoleSuccess;
}

// gc
export class GetUserRoleFailure implements Action {
  readonly type = AuthActionTypes.GetUserRoleFailure;
  constructor(public payload: any) {}
}

export type AuthActions
  = Logoff
  | LoginSuccess
  | SetLoginRedirectUrl
  | SaveResetPasswordNotificationLogs
  | Login
  | LoginFailure
  | Logout
  | LogoutSuccess
  | LogoutFailure
  | GetUser
  | GetUserSuccess
  | GetUserFailure
  | GetUserRole
  | GetUserRoleSuccess
  | GetUserRoleFailure;
