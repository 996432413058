// from c/shopping-cart/ng-shopping-cart-master

import {User} from './user.model';
import { CartItem } from './cart.model';

export interface OrderItem {
  id: number;
  videoId: number; // gc
  title: string;
  total: number;
  imageUrl: string;
  url: string;
  turl: string;
  lists: string[];
  email: string; // new 23/9/19
  uid: string;  // new 23/9/19
  orderNumber: string;  // new 23/9/19
  date: string;  // new 23/9/19

  push(orderItems: Array<OrderItem>): void;
  filter(param: (element) => boolean): any[];
}

export function createOrder(items: Array<CartItem>, user: User) {
  let grandTotal = 0;
  const orderNo = Math.random().toString(36).substring(5).toUpperCase();
 // const createdAt = new Date().toLocaleDateString('en-US');
  const createdAt = new Date().toLocaleString();

  const orderItems = items.map((item, index) => {
    grandTotal += item.total;

    return {
      id: index + 1,
      videoId: item.videoId,
      title: item.title,
      total: item.total,
      email: user.email,
      uid: user.uid,
      orderNumber: orderNo,
      date: createdAt,
    } as OrderItem;
  });
  return {
    orderNumber: orderNo,
    date: createdAt,
    orderItems: items,
    grandTotal,
    email: user.email,
    // uid: user.uid // 16/5/20  <<<<---------activate after further tests
  } as Order;
}

export interface Order {
  id: number;
  orderNumber: string;
  grandTotal: number;
  date: string;
  orderItems: Array<OrderItem>;
  email: string; // new 23/9/19
  uid: string; // new 23/9/19
}

// use for getting playlist from orders only
export interface IOrder {
  id: number;
  orderNumber: string;
  grandTotal: number;
  date: string;
  email: string;
  uid: string;
  orderItems: {
    id: number;
    videoId: number;
    title: string;
    total: number;
    imageUrl: string;
    url: string;
  //  email: string; // new 23/9/19
  //  uid: string; // new 23/9/19
  };
}



