import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { IVideo } from '../../../core/models/video.model';
import { List } from '../../../core/models/list.model';
import { VideosService } from '../../services/videos.service';

@Component({
  selector: 'app-videos-routerlink',
  templateUrl: './videos-routerlink.component.html',
  styleUrls: ['./videos-routerlink.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideosRouterlinkComponent implements OnInit {
  // @Input() ovid: IVideo = {} as IVideo;
  @Output() add: EventEmitter<IVideo> = new EventEmitter<IVideo>();
  @Output() update: EventEmitter<IVideo> = new EventEmitter<IVideo>();

  @Input() selectedListName: string;
  @Input() selectedPlaylistName: string;

  lists: List[];

  @Input()  routeOptions = [
    { name: 'Recommended', shortName: 'recommended'},
    { name: 'Recents', shortName: 'recents'},
    { name: 'All Videos', shortName: 'all'},
    { name: 'Search by Tags', shortName: 'tags'},
    { name: 'Search by Bible', shortName: 'bible'},
    { name: 'Source', shortName: 'source'},
  ];

  constructor(private videosService: VideosService) {
  }

  ngOnInit() {
  }

  changeSelected($event, list): void {
    // console.log($event.selected);
    list.selected = $event.selected;
    this.videosService.onSelectRoute(list.shortName, list.selected);
  }

}
