// from c/shopping-cart/AngularFirebaseCRUD

import { Injectable } from '@angular/core';
// import { FormControl, FormGroup } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Store } from '@ngrx/store';
import { OrdersState } from '../store/states';

import { GetOrdersBegin } from '../store/actions';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

 // ordersRef: AngularFirestoreCollection<Order>;
  successMsg = 'Data successfully saved.';

  constructor(private firestore: AngularFirestore, private store: Store<OrdersState>) {
  }

  /*
  form = new FormGroup({
    customerName: new FormControl(''),
    orderNumber: new FormControl(''),
    coffeeOrder: new FormControl(''),
    completed: new FormControl(false)
  });
*/

  getOrders() {
    this.store.dispatch(new GetOrdersBegin());
  }
}


  /*
    updateCoffeeOrder(data) {
      return this.firestore
        .collection('coffeeOrders')
        .doc(data.payload.doc.id)
        .set({ completed: true }, { merge: true });
    }

    getCoffeeOrders() {
      return this.firestore.collection('coffeeOrders').snapshotChanges();
    }

    deleteCoffeeOrder(data) {
      return this.firestore
        .collection('coffeeOrders')
        .doc(data.payload.doc.id)
        .delete();
    }
  */


