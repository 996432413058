import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { PageNotFoundComponent } from '../app-layout/components/page-not-found/page-not-found.component';

const appRoutes: Routes = [
  {
    path: 'videos',
    loadChildren: () => import('../videos/videos.module').then(mod => mod.VideosModule),
    // data: { preload: true }
  },
  {
    path: 'home',
    loadChildren: () => import('../home/home.module').then(mod => mod.HomeModule),
  },
  {
    path: 'playlists',
    loadChildren: () => import('../playlists/playlists.module').then(mod => mod.PlaylistsModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'player',
    loadChildren: () => import('../player/player.module').then(mod => mod.PlayerModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('../cart/cart.module').then(mod => mod.CartModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('../checkout/checkout.module').then(mod => mod.CheckoutModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'freevideos',
    loadChildren: () => import('../videos-free/freevideos.module').then(mod => mod.FreeVideosModule),
    // data: { preload: true }
  },
  { path: '',   redirectTo: '/videos', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        scrollPositionRestoration: 'enabled',
        enableTracing: false, // <-- debugging purposes only
        preloadingStrategy: SelectivePreloadingStrategyService,
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
