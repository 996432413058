import { PlaylistsActions, ActionTypes } from '../actions';
import { playlistsAdapter , playlistsInitialState,  PlaylistsState } from '../states';

export function playlistsReducer(state = playlistsInitialState, action: PlaylistsActions): PlaylistsState {
  // console.log(action.type);
  switch (action.type) {

    case ActionTypes.GET_PLAYLISTS_SUCCESS:
      return playlistsAdapter.addAll(action.payload, state);

    case ActionTypes.GET_PLAYLISTS_SUCCESS:
      return playlistsAdapter.addAll(action.payload, state);

    case ActionTypes.GET_PLAYLISTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case ActionTypes.LOAD_PLAYLIST_NAMES_SUCCESS:
      return {
        ...state,
        playlistNames: action.payload
      };

    case ActionTypes.UPDATE_SELECTED_LISTNAME:
      return {
        ...state,
        selectedPlaylistName: action.payload
      };

    case ActionTypes.GET_SELECTED_PLAYLISTS_SUCCESS:
      return playlistsAdapter.addAll(action.payload, state);

    case ActionTypes.SELECTED_PLAY_VIDEO:
      return {
        ...state,
        selectedPlayVideo: action.payload
      };

    case ActionTypes.RESET_SELECTED_PLAYLIST_NAME:
      return {
        ...state,
        selectedPlaylistName: null
      };

    case ActionTypes.UPDATE_ADD_PLAYLIST_NAME:
      return {
        ...state,
        addPlaylistName: action.payload
      };

    case ActionTypes.UPDATE_NEW_PLAYLIST_NAME:
      return {
        ...state,
        newPlaylistName: action.payload
      };

    case ActionTypes.RESET_ADD_NEW_STATES:
      return {
        ...state,
        newPlaylistName: null,
        addPlaylistName: []
      };

    // ----------remove-dialog lists----------- //
    case ActionTypes.UPDATE_ORDER_ITEM_LISTS:
      return {
        ...state,
        orderItemLists: action.payload
      };

    case ActionTypes.UPDATE_ORDER_ITEM_LISTS_ARRAY:
      return {
        ...state,
        orderItemListsArray: action.payload
      };

    case ActionTypes.UPDATE_ORDER_ITEM_LISTS_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case ActionTypes.UPDATE_LISTS_FOR_DELETE:
      return {
        ...state,
        listsForDelete: action.payload
      };

    case ActionTypes.RESET_DELETE_STATES:
      return {
        ...state,
        orderItemLists: [],
        orderItemListsArray: [],
        listsForDelete: []
      };

    // ----------end of remove-dialog lists----------- //

    case ActionTypes.REMOVE_VIDEO:
      return playlistsAdapter.removeOne(action.payload, state);

    default: {
      return state;
    }
  }
}


