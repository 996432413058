import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Router } from '@angular/router';

// angular-firestore modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { RootStoreModule } from './root-store/root-store.module';

// For lazy load modules, do not import in app.module
import { AppLayoutModule } from './app-layout';
import { AuthModule } from './auth/auth.module';

// modified: 20/3/20 - home module is a lazy load module
// import { HomeModule } from './home/home.module';

// // modified: 20/3/20 - videos module is now he default module
import { VideosModule } from './videos/videos.module';

import { DeviceDetectorModule } from 'ngx-device-detector';

const COMPONENTS = [
  AppComponent,
];

export const firebaseCredentials = {
  apiKey: 'AIzaSyCtK3CObYuv_nTbqVWJY-M_rqDfH1fxL5w',
  authDomain: 'drovid.firebaseapp.com',
  databaseURL: 'https://drovid.firebaseio.com',
  projectId: 'drovid',
  storageBucket: 'drovid.appspot.com/',
  messagingSenderId: 'project-27500446831',
};

@NgModule({
  declarations: COMPONENTS,
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    // CommonModule,
    FormsModule,
    AppRoutingModule,

    AppLayoutModule,
    AuthModule,
    // HomeModule, // modified: 20/3/20 - home module is a lazy load module
    VideosModule, // modified: 20/3/20 - videos module is now he default module

    AngularFireModule.initializeApp(firebaseCredentials),
    AngularFireDatabaseModule,
    AngularFirestoreModule,

    RootStoreModule,

    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 store
    }),
    ReactiveFormsModule,

    DeviceDetectorModule.forRoot(),
  ],
  exports: COMPONENTS,
  providers: [{ provide: FirestoreSettingsToken, useValue: {} }],
  bootstrap: [AppComponent]
})
export class AppModule {
  // from Angular Heroes Router
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
}

