import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OrderItem } from '../../../core/models/order.model';
import { List } from '../../../core/models/list.model';
// import {IVideo} from '../../../core/models/video.model';

/*
export function sortByTitle(a: OrderItem, b: OrderItem): number {
  return a.title.localeCompare(b.title);
}
 */
export const playlistsAdapter: EntityAdapter<OrderItem> =
  createEntityAdapter<OrderItem>({
    sortComparer: false
  });

// create new store based on EntityState
export interface PlaylistsState extends EntityState<OrderItem> {
  isLoading?: boolean;
  error?: any;
  playlistNames: List[];
  selectedPlaylistName: string;
  addPlaylistName: string[];
  newPlaylistName: string;
  listsForDelete: string[];
  orderItemLists: string[];
  orderItemListsArray: List[];
  selectedPlayVideo?: OrderItem;
}

// set the initial store
export const playlistsInitialState: PlaylistsState = playlistsAdapter.getInitialState(
  {
    isLoading: false,
    error: null,
    playlistNames: [],
    selectedPlaylistName: null,
    addPlaylistName: [],
    newPlaylistName: null,
    listsForDelete: [],
    orderItemLists: [],
    orderItemListsArray: [],
    selectedPlayVideo: null,
  }
);


