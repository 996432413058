import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ordersReducer } from './reducers';
import { OrdersEffects } from './effects';

  @NgModule({
    imports: [
      CommonModule,
      StoreModule.forFeature('orders', ordersReducer),
      EffectsModule.forFeature([OrdersEffects])
    ],

   providers: [OrdersEffects]
})

export class OrdersStoreModule {}


