import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { map, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

import { OrderItem } from '../../core/models/order.model';
import {selectSelectedList} from '../store/selectors';
import {Store} from '@ngrx/store';
import { PlaylistsState } from '../store/states';
// import { AuthState } from '../../auth/store/states';
// import { RoleService } from '../../auth/services/role.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreDataService {

  listname: string;

  constructor(
    private actions$: Actions,
    private afs: AngularFirestore,
    private store: Store<PlaylistsState>,
  //  private authStore: Store<AuthState>,
  //  private roleService: RoleService
  ) { }

  getPlaylists(uid: string) {
    const ordersRef = this.afs.collection<OrderItem>('ordersItems', ref => {
      return ref.where('uid', '==', uid);
    });
    return ordersRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
         // console.log(data);
          return {docId: doc.payload.doc.id, ...data};
        });
      }));
  }

  getSelectedPlaylists(uid: string) {
    this.store.select(selectSelectedList).pipe(
      take(1))
      .subscribe(data => this.listname = (data));

    const ordersRef = this.afs.collection<OrderItem>('ordersItems', ref => {
      return ref.where('uid', '==', uid)
        .where('lists', 'array-contains', this.listname)
        .orderBy('title', 'asc');
    });
    return ordersRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          console.log(data);
          return {docId: doc.payload.doc.id, ...data};
        });
      }));
  }

  getPlaylistsNames(uid: string) {
    const ordersRef = this.afs.collection<OrderItem>('ordersItems', ref => {
      return ref.where('uid', '==', uid);
    });
    return ordersRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data().lists;
        //  console.log(data);
        //  return {id: doc.payload.doc.id, ...data};
          return {...data};
        });
      }));
  }

}

