import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

// Components
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SidenavItemComponent } from './components/sidenav-item/sidenav-item.component';
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SignInDialogComponent } from './dialogs/signin-dialog/signin-dialog.component';

// Modules
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material Modules
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';

import * as fromLayout from './store/reducers';

import {DialogService} from './services/dialog.service';

const COMPONENTS = [
  SidenavComponent,
  SidenavItemComponent,
  LayoutComponent,
  HeaderComponent,
  NavbarComponent,
  FooterComponent,
  PageNotFoundComponent,
  SignInDialogComponent
];

@NgModule({
  imports: [
    // AppLayoutModule,
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    // @angular/materials modules
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatBadgeModule,
    MatDialogModule,

    StoreModule.forFeature('layout', fromLayout.layoutReducer),
    // EffectsModule.forFeature([LayoutEffects]),
  ],
  declarations: [
    COMPONENTS,
  ],
  exports: COMPONENTS,
  providers: [DialogService],
  entryComponents: [SignInDialogComponent]
})
export class AppLayoutModule { }
