import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {videosAdapter, VideosState} from '../states';
// use for title search
import { Title } from '../../../core/models/title.interface';
import { Tag } from '../../../core/models/tag.interface';
import { Source } from '../../../core/models/source.interface';
const {
  selectIds: _selectVideoIds,
  selectEntities: _selectVideoEntities,
  selectAll: _selectAllVideos,
  selectTotal:  videosCount,
} = videosAdapter.getSelectors();

export const selectVideosState = createFeatureSelector<VideosState>('videos');

export const selectVideosIds = createSelector(
  selectVideosState,
  _selectVideoIds
);
export const selectVideosEntities = createSelector(
  selectVideosState,
  _selectVideoEntities
);
export const selectVideosAll = createSelector(
  selectVideosState,
  _selectAllVideos
);
export const selectVideosTotal = createSelector(
  selectVideosState,
  videosCount
);

// use for playing video
/*
export const getSelectedVideo = (state: VideosState) => state.selectedVideo;
export const SelectedVideo: MemoizedSelector<object, any> = createSelector(
  selectVideosState,
  getSelectedVideo);
*/

// get tags list: 26/3/20 - use by VideosComponent
// -----------------------------------------------
export const getTags = (state: VideosState): Tag[] => state.tags;
export const getTagsList: MemoizedSelector<object, Tag[]> =
  createSelector(selectVideosState, getTags);

// get selected tag from state:
// -------------------------------
export const getSelectedTag = (state: VideosState): string => state.selectedTag;
export const SelectedTag: MemoizedSelector<object, string> =
  createSelector(selectVideosState, getSelectedTag);

// get selected book from state:
// ----------------------------
// Use it for loading videos
export const getSelectedBook = (state: VideosState): string => state.selectedBook;
export const SelectedBook: MemoizedSelector<object, string> =
  createSelector(selectVideosState, getSelectedBook);

// use for spinner
export const getLoadingStatus = createSelector(
  selectVideosState,
  state => state.isLoading
);

// use for opening/closing search form in videos
export const getSearchStatus = createSelector(
  selectVideosState,
  state => state.isSearchOpen
);

// get titles autocomplete list from state:
// ----------------------------------------
// & save it on state. Use it for mat-select
export const getTitles = (state: VideosState): Title[] => state.titles;
export const selectTitles: MemoizedSelector<object, Title[]> =
  createSelector(selectVideosState, getTitles);

// use for videos title search - new: 13/3/20
export const getSelectedId = (state: VideosState): number => state.selectedId;
export const SelectedId: MemoizedSelector<object, number> =
  createSelector(selectVideosState, getSelectedId);

// new: 13/3/20 - for loading views
// ---------------------------------------------
export const getSelectedView = (state: VideosState): string => state.selectedView;
export const selectedView: MemoizedSelector<object, string> =
  createSelector(selectVideosState, getSelectedView);

// new: 1/5/20 - for loading views
// get source list: 1/5/20 - use by VideosComponent
// -----------------------------------------------
export const getSource = (state: VideosState): Source[] => state.source;
export const getSourceList: MemoizedSelector<object, Source[]> =
  createSelector(selectVideosState, getSource);

// new: 1/5/20
// get selected source from state:
// -------------------------------
export const getSelectedSource = (state: VideosState): string => state.selectedSource;
export const SelectedSource: MemoizedSelector<object, string> =
  createSelector(selectVideosState, getSelectedSource);






















