import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {VideosState} from '../../store/states';
import {SearchService} from '../../services/search.service';
import { VideosService } from '../../services/videos.service';
import * as videosActions from '../../store/actions';

// use for title search
import { Title } from '../../../core/models/title.interface';
// use for bible search
import { Book } from '../../../core/models/book.interface';
// use for tag search
import { Tag } from '../../../core/models/tag.interface';
// use for source search
import { Source } from '../../../core/models/source.interface';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit {

  // -----------use for title search-----------
  searchFormControl = new FormControl();
  @Input() options: Title[] = [];
  @Input() filteredOptions: Observable<Title[]>;
  @Output() optionSelected = new EventEmitter();
  // -------------------------------------------
  @Input() books$: any[] = []; // new: 22/3/20 - for search by bible view
  @Input() tags: Tag[] = []; // new: 26/3/20 - for search by tag view
  @Input() viewSelected$: any; // new: 28/3/20 - for navigation hide/show views;
  @Input() sources: Source[] = []; // new: 1/5/20 - for search by source view

  // -----------use for book search----------- //
  bookOptions: Book[] = [
    { id: 1, name: 'Genesis', selected: false},
    { id: 2, name: 'Exodus', selected: false},
    { id: 3, name: 'Leviticus', selected: false},
    { id: 4, name: 'Numbers', selected: false},
    { id: 5, name: 'Deuteronomy', selected: false},
    { id: 6, name: 'Joshua', selected: false},
    { id: 7, name: 'Judges', selected: false},
    { id: 8, name: 'Ruth', selected: false},
    { id: 9, name: '1 Samuel', selected: false},
    { id: 10, name: '2 Samuel', selected: false},
    { id: 11, name: '1 Kings', selected: false},
    { id: 12, name: '2 Kings', selected: false},
    { id: 13, name: '1 Chronicles', selected: false},
    { id: 14, name: '2 Chronicles', selected: false},
    { id: 15, name: 'Ezra', selected: false},
    { id: 16, name: 'Nehemiah', selected: false},
    { id: 17, name: 'Esther', selected: false},
    { id: 18, name: 'Job', selected: false},
    { id: 19, name: 'Psalms', selected: false},
    { id: 20, name: 'Proverbs', selected: false},
    { id: 21, name: 'Ecclesiastes', selected: false},
    { id: 22, name: 'Song of Solomon', selected: false},
    { id: 23, name: 'Isaiah', selected: false},
    { id: 24, name: 'Jeremiah', selected: false},
    { id: 25, name: 'Lamentations', selected: false},
    { id: 26, name: 'Ezekiel', selected: false},
    { id: 27, name: 'Daniel', selected: false},
    { id: 28, name: 'Hosea', selected: false},
    { id: 29, name: 'Joel', selected: false},
    { id: 30, name: 'Amos', selected: false},
    { id: 31, name: 'Obadiah', selected: false},
    { id: 32, name: 'Jonah', selected: false},
    { id: 33, name: 'Micah', selected: false},
    { id: 34, name: 'Nahum', selected: false},
    { id: 35, name: 'Habakkuk', selected: false},
    { id: 36, name: 'Zephaniah', selected: false},
    { id: 37, name: 'Haggai', selected: false},
    { id: 38, name: 'Zechariah', selected: false},
    { id: 39, name: 'Malachi', selected: false},
    { id: 40, name: 'Matthew', selected: false},
    { id: 41, name: 'Mark', selected: false},
    { id: 42, name: 'Luke', selected: false},
    { id: 43, name: 'John', selected: false},
    { id: 44, name: 'Acts', selected: false},
    { id: 45, name: 'Romans', selected: false},
    { id: 46, name: '1 Corinthians', selected: false},
    { id: 47, name: '2 Corinthians', selected: false},
    { id: 48, name: 'Galatians', selected: false},
    { id: 49, name: 'Ephesians', selected: false},
    { id: 50, name: 'Philippians', selected: false},
    { id: 51, name: 'Colossians', selected: false},
    { id: 52, name: '1 Thessalonians', selected: false},
    { id: 53, name: '2 Thessalonians', selected: false},
    { id: 54, name: '1 Timothy', selected: false},
    { id: 55, name: '2 Timothy', selected: false},
    { id: 56, name: 'Titus', selected: false},
    { id: 57, name: 'Philemon', selected: false},
    { id: 58, name: 'Hebrews', selected: false},
    { id: 59, name: 'James', selected: false},
    { id: 60, name: '1 Peter', selected: false},
    { id: 61, name: '2 Peter', selected: false},
    { id: 62, name: '1 John', selected: false},
    { id: 63, name: '2 John', selected: false},
    { id: 64, name: '3 John', selected: false},
    { id: 65, name: 'Jude', selected: false},
    { id: 66, name: 'Revelation', selected: false},
  ];

  constructor(
    private videosStore: Store<VideosState>,
    private searchService: SearchService,
    private videosService: VideosService
  ) {
    this.searchService.loadTitlesForAutocomplete();
  }

  ngOnInit() {
    // modified: title search - 14/3/20
    this.filteredOptions = this.searchFormControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.title),
        map(data => data ? this._filter(data) : this.options.slice())
      );
  }

  // --------------use for title search--------------
  // modified: title search - 14/3/20
  displayFn(title?: Title): string | undefined {
    return title ? title.title : undefined;
  }

  // modified: title search - 14/3/20
  private _filter(input: string): Title[] {
    const filterValue = input.toLowerCase().replace(/\s+/g, '');
    return this.options.filter(option => (option.title + option.subtitle).toLowerCase().includes(filterValue));
  }

  // use for title search
  public clearSelectedValue() {
    if (this.searchFormControl) {
      this.searchFormControl.setValue('');
    }
  }

  // use for title search
  public onKey($event: KeyboardEvent) {
  }

  // use for title search
  public onBlur($event: MouseEvent) {
  }

  // use for title search
  public onFocus($event: any) {
    if (this.optionSelected) {
      return;
    }
  }
  // --------------end use for title search--------------

  // modified: title search - 14/3/20
  onTitleSelection(selected) {
    this.searchService.updateTitleSearchId(selected.id);
    this.searchService.LoadVideoByTitleSearch(selected.id);
  }

  changeBookSelected($event, book): void {
    console.log($event.selected);
    book.selected = $event.selected;
    this.videosStore.dispatch(new videosActions.UpdateSelectedBook(book.name));
    this.videosService.onBookSelection(book.name, book.selected);
  }

  // modified: tag search - 26/3/20
  changeTagSelected($event, tag): void {
    console.log($event.selected);
    tag.selected = $event.selected;
    this.videosStore.dispatch(new videosActions.UpdateSelectedTag(tag.name));
    this.videosService.onTagSelected(tag.name, tag.selected);
  }

  // new: source search - 1/5/20
  changeSourceSelected($event, source): void {
    console.log($event.selected);
    source.selected = $event.selected;
    this.videosStore.dispatch(new videosActions.UpdateSelectedSource(source.name));
    this.videosService.onSourceSelected(source.name, source.selected);
  }

}

