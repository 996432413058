import { OrdersActions, OrdersActionTypes} from '../actions';
import { ordersAdapter, ordersInitialState, OrdersState } from '../states';

export function ordersReducer(ordersState = ordersInitialState, action: OrdersActions): OrdersState {
  // console.log(action.type);
  switch (action.type) {

    case OrdersActionTypes.ADD_ALL:
      return ordersAdapter.addAll(action.payload, ordersState);

    case OrdersActionTypes.CREATE_NEW_ORDER:
      return ordersAdapter.addOne(action.payload, ordersState);

    case OrdersActionTypes.RESET_ORDERS: {
      return ordersInitialState;
    }

    default: {
      return ordersState;
    }
  }
}
