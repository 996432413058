import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrdersState } from '../states/order.state';
import { ordersAdapter } from '../states';
import {selectPlaylistsEntities} from '../../../playlists/store/selectors';

const {
  selectIds: _selectOrdersIds,
  selectEntities: _selectOrdersEntities,
  selectAll: _selectOrdersAll,
  selectTotal: _selectOrdersTotal,
} = ordersAdapter.getSelectors();

export const getOrderState = createFeatureSelector<OrdersState>('orders');


export const getAllOrders = createSelector(
  getOrderState,
  state => state.orders
);

export const getLoadingStatus = createSelector(
  getOrderState,
  state => state.isLoading
);

export const selectOrdersAll = createSelector(
  getOrderState,
  _selectOrdersAll
);


