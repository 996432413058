import { animate, style, transition, trigger } from '@angular/animations';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
// import { IPizzeria } from '../../shared/states/ui/ui.interface';

// testing footer - to delete
export interface IPizzeria {
  readonly name: string;
  readonly phone: string;
  readonly url: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() pizzeria: IPizzeria; // testing footer - to delete
  @Output() onOpenHome = new EventEmitter();
}
