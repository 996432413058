import { Action } from '@ngrx/store';
import {IVideo, IVideoWithMarkCart} from '../../../core/models/video.model';

export enum VideosActionTypes {
  // new 16/3/20: for storing user navigations in state
  UPDATE_SELECTED_VIEW = '[Videos] Update Selected View',

  // ----------------- from home module-------------------- //
  QUERY_RECOMMENDED = '[Videos] Query Recommended',
  ADD_ALL_RECOMMENDED = '[Videos] Add All Recommended',
  ADD_ALL_RECOMMENDED_FAILURE = '[Videos] Add All Recommended Failure',

  QUERY_RECENTS = '[Videos] Query Recents',
  ADD_ALL_RECENTS = '[Videos] Add All Recents',
  ADD_ALL_RECENTS_FAILURE = '[Videos] Add All Recents Failure',

// ----------------- from home module end-------------------- //
  LOAD_ALL_VIDEOS_BEGIN = '[Videos] Load All Videos Begin',
  LOAD_ALL_VIDEOS_SUCCESS = '[Videos] Load All Videos Success',
  LOAD_ALL_VIDEOS_FAILURE = '[Videos] Load All Videos Failure',

  // new: 22/3/20
  LOAD_VIDEOS_BY_BIBLE_BEGIN = '[Search] Load Videos By Bible Begin',
  LOAD_VIDEOS_BY_BIBLE_SUCCESS = '[Search] Load Videos By Bible Success',
  LOAD_VIDEOS_BY_BIBLE_FAILURE = '[Search] Load Videos By Bible Failure',

  LOAD_VIDEOS_BY_BOOK_BEGIN = '[Search] Load Videos By Book Begin',
  LOAD_VIDEOS_BY_BOOK_SUCCESS = '[Search] Load Videos By Book Success',
  LOAD_VIDEOS_BY_BOOK_FAILURE = '[Search] Load Videos By Book Failure',

  // modified: title search - 14/3/20
  LOAD_VIDEOS_BY_ID_BEGIN = '[Search] Load Videos By Id Begin',
  LOAD_VIDEOS_BY_ID_SUCCESS = '[Search] Load Videos By Id Success',
  LOAD_VIDEOS_BY_ID_FAILURE = '[Search] Load Videos By Id Failure',

  // ------------------------------TAG_SORT ---------------------------
  LOAD_ALL_BY_TAG_SORT_BEGIN = '[Videos] Load ALL By Tag Sort Begin',
  LOAD_ALL_BY_TAG_SORT_SUCCESS = '[Videos] Load ALL By Tag Sort Success',
  LOAD_ALL_BY_TAG_SORT_FAILURE = '[Videos] Load ALL By Tag Sort Failure',

  // load mat-chips
  LOAD_TAGS_BEGIN = '[Search] Load Tags Begin',
  LOAD_TAGS_DONE = '[Search] Load Tags Done',
  LOAD_TAGS_FAILURE = '[Search] Load Tags Failure',
  UPDATE_SELECTED_TAG = '[Search] Update Selected Tag',
  INITIALIZE_SELECTED_TAG = '[Search] Initialize Selected Tag',

  // load results after user selected mat-chip
  LOAD_VIDEOS_BY_TAG_BEGIN = '[Search] Load Videos By Tag Begin',
  LOAD_VIDEOS_BY_TAG_SUCCESS = '[Search] Load Videos By Tag Success',
  LOAD_VIDEOS_BY_TAG_FAILURE = '[Search] Load Videos By Tag Failure',

  // load source mat-chips
  LOAD_SOURCE_BEGIN = '[Search] Load Source Begin',
  LOAD_SOURCE_DONE = '[Search] Load Source Done',
  LOAD_SOURCE_FAILURE = '[Search] Load Source Failure',

  LOAD_VIDEOS_BY_SOURCE_BEGIN = '[Search] Load Videos By Source Begin',
  LOAD_VIDEOS_BY_SOURCE_SUCCESS = '[Search] Load Videos By Source Success',
  LOAD_VIDEOS_BY_SOURCE_FAILURE = '[Search] Load Videos By Source Failure',

  UPDATE_SELECTED_SOURCE = '[Search] Update Selected Source',
  // -------------------------------------------------------------------

  UPDATE_SELECTED_BOOK = '[Search] Update Selected Book',

  // use for videos title search - 13/3/20
  UPDATE_SELECTED_ID = '[Search] Update Selected Id',

  UPDATE_TITLES_STATE_BEGIN = '[Videos] Load Titles State Begin',
  UPDATE_TITLES_STATE_DONE = '[Videos] Load Titles State Done',
  UPDATE_TITLES_STATE_FAILURE = '[Videos] Load Titles State Failure',

  SELECTED_VIDEO = '[Videos] Selected Video',
  REMOVE_VIDEO = '[Videos] Remove Video',
  BOOKMARK_VIDEO = '[Videos] Bookmark Video',
  UNDO_BOOKMARK_VIDEO = '[Videos] Undo Bookmark Video',

  SHOW_VIDEO_DESCRIPTION =  '[Videos] Show Video Description',
  HIDE_VIDEO_DESCRIPTION =  '[Videos] Hide Video Description',

  UPDATE_ADDED_TO_CART_BEGIN = '[Videos] Update Added To Cart Begin',
  UPDATE_ADDED_TO_CART = '[Videos] Update Added To Cart',
  UNDO_ADDED_TO_CART = '[Videos] Undo Added To Cart',

  Initialize_Videos_State = '[Videos] Initialize Videos State',
}
// ----------------- from home module-------------------- //
// new 16/3/20: for storing user navigations in state
export class UpdateSelectedView implements Action {
  readonly type = VideosActionTypes.UPDATE_SELECTED_VIEW;
  constructor(public payload: string) { }
}

export class QueryRecommended implements Action {
  readonly type = VideosActionTypes.QUERY_RECOMMENDED;
}

export class AddAllRecommended implements Action {
  readonly type = VideosActionTypes.ADD_ALL_RECOMMENDED;
  constructor(public payload: IVideoWithMarkCart[]) {
  }
}

export class AddAllRecommendedFailure implements Action {
  readonly type = VideosActionTypes.ADD_ALL_RECOMMENDED_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class QueryRecents implements Action {
  readonly type = VideosActionTypes.QUERY_RECENTS;
}

export class AddAllRecents implements Action {
  readonly type = VideosActionTypes.ADD_ALL_RECENTS;
  constructor(public payload: IVideoWithMarkCart[]) {
  }
}

export class AddAllRecentsFailure implements Action {
  readonly type = VideosActionTypes.ADD_ALL_RECENTS_FAILURE;
  constructor(public payload: { error: any }) {}
}

// ------------------- Load Videos -----------------------
export class LoadAllVideosBegin implements Action {
  readonly type = VideosActionTypes.LOAD_ALL_VIDEOS_BEGIN;
}

export class LoadAllVideosSuccess implements Action {
  readonly type = VideosActionTypes.LOAD_ALL_VIDEOS_SUCCESS;
  constructor(public payload: Array<IVideoWithMarkCart>) {
  }
}

export class LoadAllVideosFailure implements Action {
  readonly type = VideosActionTypes.LOAD_ALL_VIDEOS_FAILURE;
  constructor(public payload: { error: any }) {}
}

// modified: title search - 14/3/20
export class LoadVideoByIdBegin implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_ID_BEGIN;
  constructor(public payload: number) {}
}

// modified: title search - 14/3/20
export class LoadVideoByIdSuccess implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_ID_SUCCESS;
  constructor(public payload: Array<IVideoWithMarkCart>) {
  }
}

// modified: title search - 14/3/20
export class LoadVideoByIdFailure implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_ID_FAILURE;
  constructor(public payload: { error: any }) {}
}

// -----------------------------------------------------------
export class LoadVideosByBibleBegin implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_BIBLE_BEGIN;
}

export class LoadVideosByBibleSuccess implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_BIBLE_SUCCESS;
  constructor(public payload: Array<IVideoWithMarkCart>) {
  }
}

export class LoadVideosByBibleFailure implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_BIBLE_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class LoadVideosByBookBegin implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_BOOK_BEGIN;
}

export class LoadVideosByBookSuccess implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_BOOK_SUCCESS;
  constructor(public payload: Array<IVideoWithMarkCart>) {
  }
}

export class LoadVideosByBookFailure implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_BOOK_FAILURE;
  constructor(public payload: { error: any }) {}
}
// -----------------------------------------------------------

// ------------------------------testing ------------------------------------
export class LoadAllByTagSortBegin implements Action {
  readonly type = VideosActionTypes.LOAD_ALL_BY_TAG_SORT_BEGIN;
}

export class LoadAllByTagSortSuccess implements Action {
  readonly type = VideosActionTypes.LOAD_ALL_BY_TAG_SORT_SUCCESS;
  constructor(public payload: Array<IVideoWithMarkCart>) {
  }
}

export class LoadAllByTagSortFailure implements Action {
  readonly type = VideosActionTypes.LOAD_ALL_BY_TAG_SORT_FAILURE;
  constructor(public payload: { error: any }) {}
}
// ------------------------------testing ------------------------------------

export class LoadVideosByTagBegin implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_TAG_BEGIN;
}

export class LoadVideosByTagSuccess implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_TAG_SUCCESS;
  constructor(public payload: Array<IVideoWithMarkCart>) {
  }
}

export class LoadVideosByTagFailure implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_TAG_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class LoadTagsBegin implements Action {
  readonly type = VideosActionTypes.LOAD_TAGS_BEGIN;
}

export class LoadTagsDone implements Action {
  readonly type = VideosActionTypes.LOAD_TAGS_DONE;
  constructor(public payload: any) {
  }
}

export class LoadTagsFailure implements Action {
  readonly type = VideosActionTypes.LOAD_TAGS_FAILURE;
  constructor(public payload: { error: any }) {}
}

// Update state property "SelectedTag" upon user selection
export class UpdateSelectedTag implements Action {
  readonly type = VideosActionTypes.UPDATE_SELECTED_TAG;
  constructor(public payload: string) { }
}

// Initialize state property "SelectedTag" upon user selection "Search By Tag"
export class InitializeSelectedTag implements Action {
  readonly type = VideosActionTypes.INITIALIZE_SELECTED_TAG;
}

export class UpdateSelectedBook implements Action {
  readonly type = VideosActionTypes.UPDATE_SELECTED_BOOK;
  constructor(public payload: string) { }
}

// ---------------new: 1/5/20 -----------------------
export class LoadSourceBegin implements Action {
  readonly type = VideosActionTypes.LOAD_SOURCE_BEGIN;
}

export class LoadSourceDone implements Action {
  readonly type = VideosActionTypes.LOAD_SOURCE_DONE;
  constructor(public payload: any) {
  }
}

export class LoadSourceFailure implements Action {
  readonly type = VideosActionTypes.LOAD_SOURCE_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class UpdateSelectedSource implements Action {
  readonly type = VideosActionTypes.UPDATE_SELECTED_SOURCE;
  constructor(public payload: string) { }
}

export class LoadVideosBySourceBegin implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_SOURCE_BEGIN;
}

export class LoadVideosBySourceSuccess implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_SOURCE_SUCCESS;
  constructor(public payload: Array<IVideoWithMarkCart>) {
  }
}

export class LoadVideosBySourceFailure implements Action {
  readonly type = VideosActionTypes.LOAD_VIDEOS_BY_SOURCE_FAILURE;
  constructor(public payload: { error: any }) {}
}

// ----------------------------------------------------
// User selects video to play
export class SelectedVideo implements Action {
  readonly type = VideosActionTypes.SELECTED_VIDEO;
  constructor(public payload: IVideo) {}
}

// --------mat-card-actions section--------------------
// Remove video from state entity
export class RemoveVideo implements Action {
  readonly type = VideosActionTypes.REMOVE_VIDEO;
  constructor(public payload: number) {}
}

// Bookmark video in state entity
export class BookmarkVideo implements Action {
  readonly type = VideosActionTypes.BOOKMARK_VIDEO;
  constructor( public payload: number) {}
}

export class UndoBookmarkVideo implements Action {
  readonly type = VideosActionTypes.UNDO_BOOKMARK_VIDEO;
  constructor( public payload: number ) {}
}

export class ShowVideoDescription implements Action {
  readonly type = VideosActionTypes.SHOW_VIDEO_DESCRIPTION;
  constructor( public payload: number) {}
}

export class HideVideoDescription implements Action {
  readonly type = VideosActionTypes.HIDE_VIDEO_DESCRIPTION;
  constructor( public payload: number ) {}
}
// --------end of mat-card-actions section--------------------

export class UpdateAddedToCartBegin implements Action {
  readonly type = VideosActionTypes.UPDATE_ADDED_TO_CART_BEGIN;
}

export class UpdateAddedToCart implements Action {
  readonly type = VideosActionTypes.UPDATE_ADDED_TO_CART;
  constructor( public payload: number ) {}
}

export class UndoAddedToCart implements Action {
  readonly type = VideosActionTypes.UNDO_ADDED_TO_CART;
  constructor( public payload: number ) {}
}
// ----------------------------------------------------------------------
export class UpdateTitlesStateBegin implements Action {
  readonly type = VideosActionTypes.UPDATE_TITLES_STATE_BEGIN;
}

export class UpdateTitlesStateDone implements Action {
  readonly type = VideosActionTypes.UPDATE_TITLES_STATE_DONE;
  constructor(public payload: any) {
  }
}

export class UpdateTitlesStateFailure implements Action {
  readonly type = VideosActionTypes.UPDATE_TITLES_STATE_FAILURE;
  constructor(public payload: { error: any }) {}
}

// use for videos title search - 13/3/20
export class UpdateSelectedId implements Action {
  readonly type = VideosActionTypes.UPDATE_SELECTED_ID;
  constructor(public payload: number) { }
}

// fix bug - 15/3/20 - ref: Jeremy Koehr
export class InitializeVideosState implements Action {
  readonly type = VideosActionTypes.Initialize_Videos_State;
}

export type VideosActions
  = UpdateSelectedView
  | QueryRecommended | AddAllRecommended | AddAllRecommendedFailure
  | QueryRecents | AddAllRecents | AddAllRecentsFailure
  | LoadAllVideosBegin | LoadAllVideosSuccess | LoadAllVideosFailure
  | LoadVideoByIdBegin | LoadVideoByIdSuccess | LoadVideoByIdFailure
  | LoadVideosByBibleBegin | LoadVideosByBibleSuccess | LoadVideosByBibleFailure
  | LoadVideosByBookBegin | LoadVideosByBookSuccess | LoadVideosByBookFailure
  | LoadAllByTagSortBegin | LoadAllByTagSortSuccess | LoadAllByTagSortFailure
  | LoadVideosByTagBegin | LoadVideosByTagSuccess | LoadVideosByTagFailure
  | LoadVideosBySourceBegin | LoadVideosBySourceSuccess | LoadVideosBySourceFailure
  | LoadTagsBegin | LoadTagsDone | LoadTagsFailure
  | LoadSourceBegin | LoadSourceDone | LoadSourceFailure
  | UpdateTitlesStateBegin | UpdateTitlesStateDone | UpdateTitlesStateFailure
  | UpdateSelectedTag | UpdateSelectedBook | UpdateSelectedId | UpdateSelectedSource
  | InitializeSelectedTag
  | SelectedVideo
  | RemoveVideo
  | BookmarkVideo | UndoBookmarkVideo
  | ShowVideoDescription | HideVideoDescription
  | UpdateAddedToCartBegin | UpdateAddedToCart | UndoAddedToCart
  | InitializeVideosState;




