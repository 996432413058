import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IVideo, IVideoWithMarkCart} from '../../../core/models/video.model';

// export const videosAdapter: EntityAdapter<IVideo> =
export const videosAdapter: EntityAdapter<IVideoWithMarkCart> =
  createEntityAdapter<IVideoWithMarkCart>({
    sortComparer:  false
  });

// create new store based on EntityState
// export interface VideosState extends EntityState<IVideo> {
export interface FreeVideosState extends EntityState<IVideoWithMarkCart> {
  isBookmarked: boolean;
  isLoading?: boolean;
  isShowDescription: boolean;
 // selectedPlayVideo?: IVideo;
  error?: any;
}

// set the initial store
export const initialState: FreeVideosState = videosAdapter.getInitialState(
  {
    isBookmarked: false,
    isLoading: false,
    isShowDescription: false,
   // selectedPlayVideo: null,
    error: null,
  });




