import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PlaylistsState} from '../store/states';
import {
  selectPlaylistsAll,
  SelectOrderItemLists,
  SelectOrderItemListsArray
} from '../store/selectors';

import {List} from '../../core/models/list.model';

import {Observable, of, Subscription} from 'rxjs';
import {take, withLatestFrom} from 'rxjs/operators';

import * as playlistsActions from '../../playlists/store/actions';
import * as PlayerAction from '../../player/store/actions';

import { PlayerState } from '../../player/store/states';
// import * as VideosAction from '../../videos/store/actions';
import {selectUser} from '../../auth/store/selectors';

import { AuthState } from '../../auth/store/states';
import { User } from '../../core/models/user.model'; // testing
import { FirestoreDataService } from '../services/firestore-data.service';  // testing


@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  private arr = [];
  private lists: List[] = [];
  private _arr = [];
  private _lists: List[] = [];

  private listArray = [];

  dummyList = {id: -1, name: 'None', checked: true};

  user: User;

  constructor(private playlistsStore: Store<PlaylistsState>,
              private playerStore: Store<PlayerState>,
              private authStore: Store<AuthState>,
              private dataService: FirestoreDataService,
  ) { }

  selectItems$() {
    this.playlistsStore.dispatch(new playlistsActions.GetPlaylistsBegin());
  }

  selectListItems$() {
    this.playlistsStore.dispatch(new playlistsActions.LoadPlaylistNamesBegin());
  }

  // ADD PLAYLISTS-------------------------------//
  getPlaylistNames() {
    this.playlistsStore.select(selectPlaylistsAll)
      .subscribe(items => items.map((v) => {
        this.arr.push(v.lists);
      }));

    if (this.arr.length > 0) {
      const filteredArr = this.arr.filter(element => element !== undefined);
      const concatArr = [].concat(...filteredArr);
      const noDuplicatesArr = concatArr.filter((el, i, a) => i === a.indexOf(el));
      const sortedArr = noDuplicatesArr.sort((a, b) => a.localeCompare(b));

      if (sortedArr.length > 0) {
        this.lists = [];
        for (let i = 0; i < sortedArr.length; i++) {
          const newValue = {
            id: i,
            name: sortedArr[i],
            checked: false,
          };
          this.lists.push(newValue);
        }
        return of(this.lists);
      }
    }
  }

  // CREATE PLAYLISTS NAMES ARRAY ------ -----------------//
  getOrderItemListsArray() {

    this.playlistsStore.select(SelectOrderItemLists).pipe(
      take(1))
      .subscribe(val => this._arr = (val));

    console.log(this._arr);

    if (this._arr === undefined) {
      console.log('SelectOrderItemLists return undefined');
      this._lists = [];
      const newValue = {
        id: -1,
        name: 'None',
        checked: true,
      };
      this._lists.push(newValue);
      return of(this._lists);

    } else if (this._arr.length === 0) {
      console.log('SelectOrderItemLists return array(0)');
      this._lists = [];
      const newValue = {
        id: -1,
        name: 'None',
        checked: true,
      };
      this._lists.push(newValue);
      return of(this._lists);
    }

    const filteredArr: string[] = this._arr.filter(element => element !== undefined);
    const concatArr: string[] = [].concat(...filteredArr);
    const noDuplicatesArr: string[] = concatArr.filter((el, i, a) => i === a.indexOf(el));
    const sortedArr: string[] = noDuplicatesArr.sort((a, b) => a.localeCompare(b));

    if (sortedArr.length > 0) {
      this._lists = [];
      for (let i = 0; i < sortedArr.length; i++) {
        const newValue = {
          id: i,
          name: sortedArr[i],
          checked: false,
        };
        this._lists.push(newValue);
      }
    }

    //  console.log(this._Lists);
    return of(this._lists);
  }

  // SelectedItem(video) { // 16/5/20
  SelectedPlayItem(video) {
    this.playerStore.dispatch(new PlayerAction.SelectedPlayVideo(video));
    // this.playlistsStore.dispatch(new playlistsActions.SelectedVideo(video));
  }

  SelectedName(name: string, selected: boolean) {
    if (selected) {
      console.log(name);
      console.log(selected);
      this.playlistsStore.dispatch(new playlistsActions.UpdateSelectedListName(name));
      this.playlistsStore.dispatch(new playlistsActions.GetSelectedPlaylistsBegin());
    }
  }

}

