// from ng-shopping-cart-master

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store} from '@ngrx/store';
import { User as FirebaseUser } from 'firebase';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { CartItem } from '../../../core/models/cart.model';
import { AuthDataService } from '../../services/auth-data.service';
import { User } from '../../../core/models/user.interface';
import { AuthState } from '../states/auth.states';
import { CartDataService } from '../../../cart/services/cart-data.service';
import {
  GetCartItemFail,
  GetCartItemSuccess,
  ResetCart
} from '../../../cart/store/actions';
import { ResetOrders } from '../../../orders/store/actions/orders.actions';
import {
  AuthActionTypes,
  GetUserFailure,
  GetUserSuccess,
  LoginFailure,
  LoginSuccess,
  LogoutFailure,
  LogoutSuccess,
} from '../actions/auth.action';
import { getUser } from '../selectors/auth.selector';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthDataService,
    private router: Router,
    private authStore: Store<AuthState>,
    private cartService: CartDataService,
  ) {
  }

  @Effect()
  login$ = this.actions$.pipe(
    ofType(AuthActionTypes.Login),
    exhaustMap(_ =>
      this.authService.googleLogin().pipe(
        map(credential => {
          const {
            uid,
            email,
            displayName,
            photoURL
          } = credential.user as FirebaseUser;
          const user: User = {
            uid,
            email,
            displayName,
            photoURL
          };
          return new LoginSuccess(user);
        }),
        catchError(error => of(new LoginFailure(error)))
      )
    )
  );

  @Effect()
  loginSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess, AuthActionTypes.GetUserSuccess),
    withLatestFrom(this.authStore.select(getUser)),
    concatMap(([_, user]) =>
      this.cartService.getCartItem(user.uid).pipe(
        take(1),
        map(
          (cartItems: { carts: CartItem[] }) =>
            new GetCartItemSuccess(cartItems)
        ),
        catchError(err => of(new GetCartItemFail(err)))
      )
    )
  );

  @Effect()
  getUser$ = this.actions$.pipe(
    ofType(AuthActionTypes.GetUser),
    exhaustMap(_ =>
      this.authService.getUserFromFirebaseAuth().pipe(
        map((firebaseUser: FirebaseUser) => {
          const {uid, email, displayName, photoURL} = firebaseUser;
          const user: User = {
            uid,
            email,
            displayName,
            photoURL
          };
          return new GetUserSuccess(user);
        }),
        catchError(error => of(new GetUserFailure(error)))
      )
    )
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    exhaustMap(_ =>
      this.authService.firebaseSignOut().pipe(
        map(res => new LogoutSuccess()),
        catchError(err => of(new LogoutFailure(err)))
      )
    )
  );

  @Effect()
  logoutSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.LogoutSuccess),
    tap(_ => this.router.navigate([''])),
    switchMap(_ => [new ResetOrders(), new ResetCart()])
  );

/*
  @Effect()
  getUserRole$ = this.actions$.pipe(
    ofType(AuthActionTypes.GetUserRole),
      switchMap(() => {

      const UserEmailId = this.userInfoService.getUserEmailId();
      const rolesRef = this.afs.collection<any>('roles', ref => {
        return ref.where('userId', '==', UserEmailId);
      });

      return rolesRef.snapshotChanges().pipe(
        take(1),
        map(arr => {
          return arr.map(doc => {
            const data: string = doc.payload.doc.data().role;
            console.log(data);
            //  return data;
            if ((data) === 'isSuperUser') {
             // this.authStore.dispatch(new AuthActions.GetUserRoleSuccess());
              switchMap(() => [new GetUserRoleSuccess(true)]);
            }
          });
        }));
    }));
  */
}
