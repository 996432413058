import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

// import { PlayerEffects } from './effects';

import { playerReducer } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('player', playerReducer),
   // EffectsModule.forFeature([VideosEffects])
  ],

 // providers: [VideosEffects]
})

export class PlayerStoreModule {}
