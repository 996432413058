import {LayoutActions, ActionTypes} from '../actions';
import { InitialState, LayoutState } from '../states';

export interface State {
  showSidenav: boolean;
}

export function layoutReducer(state = InitialState, action: LayoutActions): LayoutState {
  // console.log(action.type);
  switch (action.type) {

    case ActionTypes.CloseSidenav: {
      return {
        ...state,
        showSidenav: false,
      };
    }

    case ActionTypes.OpenSidenav: {
      return {
        ...state,
        showSidenav: true,
      };
    }

    case ActionTypes.UpdateDeviceOS:
      return {
        ...state,
        deviceOS: action.payload
      };

    default:
      return state;
  }
}

export const getShowSidenav = (state: State) => state.showSidenav;
