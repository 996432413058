import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';

import { User } from '../../../core/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  logo = require('../../../../assets/logo.jpg');
  @Output() openMenu = new EventEmitter();
  @Output() openSetting = new EventEmitter();
  @Output() openHelp = new EventEmitter();
  @Output() openUser = new EventEmitter();

  @Input() user: User;
  @Output() loginClicked = new EventEmitter(); // trivia
  @Output() logoutClicked = new EventEmitter();  // trivia

  @Input() cartItemCount: number;
  @Output() cartBtnClick = new EventEmitter();

  constructor() {

  }

}
