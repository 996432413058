import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { playlistsAdapter, PlaylistsState } from '../states';
import {List} from '../../../core/models/list.model';
import { each } from 'lodash';

// basic selectors
const {
  selectIds: _selectPlaylistsIds,
  selectEntities: _selectPlaylistsEntities,
  selectAll: _selectPlaylistsAll,
  selectTotal: _selectPlaylistsTotal,
} = playlistsAdapter.getSelectors();

export const selectPlaylistsState = createFeatureSelector<PlaylistsState>(
  'playlists'
);

export const selectPlaylistsIds = createSelector(
  selectPlaylistsState,
  _selectPlaylistsIds
);
export const selectPlaylistsEntities = createSelector(
  selectPlaylistsState,
  _selectPlaylistsEntities
);
export const selectPlaylistsAll = createSelector(
  selectPlaylistsState,
  _selectPlaylistsAll
);
export const selectPlaylistsTotal = createSelector(
  selectPlaylistsState,
  _selectPlaylistsTotal
);

// use for playing video
// ----------------------
export const getSelectedVideo = (state: PlaylistsState) => state.selectedPlayVideo;

export const SelectedVideo: MemoizedSelector<object, any> = createSelector(
  selectPlaylistsState,
  getSelectedVideo);
// -----------------------------------------------------------------------------


// Use for playlist names mat-select
// ---------------------------------
export const getPlaylistNames = (state: PlaylistsState): List[] => state.playlistNames;
export const selectPlaylistNames: MemoizedSelector<object, List[]> =
  createSelector(selectPlaylistsState, getPlaylistNames);
// -----------------------------------------------------------------------------------//


// get selected list name:
// ----------------------
// user selected list. Stored in state & use it to filter the playlists videos search-loading-spinner
export const getSelectedList = (state: PlaylistsState): string => state.selectedPlaylistName;
export const selectSelectedList: MemoizedSelector<object, string> =
  createSelector(selectPlaylistsState, getSelectedList);

// not used - kiv
export const getPlaylistVideos = createSelector(selectPlaylistsEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

// https://ultimatecourses.com/blog/ngrx-store-understanding-state-selectors
// convert our entities-based selector into an array format,
// for consumption via ngFor
/*
export const getAllPizzas = createSelector(getPizzasEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});
*/

// use for reset
// -------------
export const getSelectedPlaylistName = (state: PlaylistsState) => state.selectedPlaylistName;

export const SelectedPlaylistName: MemoizedSelector<object, any> = createSelector(
  selectPlaylistsState,
  getSelectedPlaylistName);
// -----------------------------------------------------------------------------

// checklist value updated to state. Get it for adding to playlist
// ---------------------------------------------------------------
export const getAddPlaylistName = (state: PlaylistsState) => state.addPlaylistName;
export const SelectAddPlaylistName: MemoizedSelector<object, any> = createSelector(
  selectPlaylistsState,
  getAddPlaylistName);
// -----------------------------------------------------------------------------

// get new playlist name from state. Use for updating ordersItems docs
// -------------------------------------------------------------------
export const getNewPlaylistName = (state: PlaylistsState) => state.newPlaylistName;
export const SelectNewPlaylistName: MemoizedSelector<object, any> = createSelector(
  selectPlaylistsState,
  getNewPlaylistName);
// -----------------------------------------------------------------------------

// get orderItem playlists from state. Use for deleting items from listsArray in docs
// -------------------------------------------------------------------
export const getOrderItemLists = (state: PlaylistsState) => state.orderItemLists;
export const SelectOrderItemLists: MemoizedSelector<object, any> = createSelector(
  selectPlaylistsState,
  getOrderItemLists);
// -----------------------------------------------------------------------------

// get modified orderItem playlists array from state.
// Use for deleting items from listsArray in docs
// -------------------------------------------------------------------
export const getOrderItemListsArray = (state: PlaylistsState) => state.orderItemListsArray;
export const SelectOrderItemListsArray: MemoizedSelector<object, any> = createSelector(
  selectPlaylistsState,
  getOrderItemListsArray);
// -----------------------------------------------------------------------------

// Use for deleting items from listsArray in docs
// -------------------------------------------------------------------
export const getListsForDelete = (state: PlaylistsState) => state.listsForDelete;
export const SelectListsForDelete: MemoizedSelector<object, any> = createSelector(
  selectPlaylistsState,
  getListsForDelete);
// -----------------------------------------------------------------------------

/*
export const selectLists = createSelector(
  selectPlaylistsState,
  // tslint:disable-next-line:no-shadowed-variable
  PlaylistsState => {
    const items: List[] = [];
    each(PlaylistsState.entities, lists => {
      items.push(lists);
    });

    return items;
  }
);
*/
