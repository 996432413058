import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { layoutReducer } from './reducers';
// import { AppLayoutEffects } from './effects';

  @NgModule({
    imports: [
      CommonModule,
     // StoreModule.forFeature('layout', layoutReducer), // <-----<<< featureName must be correct. See selectors
      // StoreModule.forFeature('monkey', featureReducer), //<------------<<< this will not work
      // EffectsModule.forFeature([AppLayoutEffects])
    ],

   // providers: [AppLayoutEffects]
    providers: []
})

export class AppLayoutStoreModule {}


