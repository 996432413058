import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {IVideo, IVideoWithMarkCart} from '../../../core/models/video.model';

// create new adapter
export const playerAdapter: EntityAdapter<IVideo> =
  createEntityAdapter<IVideo>({
    sortComparer:  false
  });

// create new store based on EntityState
export interface PlayerState extends EntityState<IVideo> {
  isLoading?: boolean;
  isPlaying?: boolean;
  error?: any;
  selectedPlayVideo?: IVideo;
}

// set the initial store
export const playerInitialState: PlayerState = playerAdapter.getInitialState(
  {
    isLoading: false,
    isPlaying: false,
    error: null,
    selectedPlayVideo: null,
  });
