import { Actions, ActionTypes} from '../actions';
import { videosAdapter, initialState, FreeVideosState } from '../states';

export function freeVideosReducer(state = initialState, action: Actions): FreeVideosState {
  console.log(action.type);
  switch (action.type) {

    case ActionTypes.QUERY_FOC:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.LOAD_ENTITIES_FOC:
      return videosAdapter.addAll(action.payload, state);
/*
    case ActionTypes.SELECTED_PLAY_VIDEO:
      return {
        ...state,
        selectedPlayVideo: action.payload
      };
*/
    case ActionTypes.REMOVE_VIDEO:
      return videosAdapter.removeOne(action.payload, state);

    case ActionTypes.BOOKMARK_VIDEO:
      const updatedVideo = {
        id: action.payload,
        changes: { isBookmarked: true }
      };
      return videosAdapter.updateOne(updatedVideo, state);

    case ActionTypes.UNDO_BOOKMARK_VIDEO:
      const updateVideo = {
        id: action.payload,
        changes: { isBookmarked: false }
      };
      return videosAdapter.updateOne(updateVideo, state);

    case ActionTypes.SHOW_VIDEO_DESCRIPTION:
      const show = {
        id: action.payload,
        changes: { isShowDescription: true }
      };
      return videosAdapter.updateOne(show, state);

    case ActionTypes.HIDE_VIDEO_DESCRIPTION:
      const hide = {
        id: action.payload,
        changes: { isShowDescription: false }
      };
      return videosAdapter.updateOne(hide, state);

    default: {
      return state;
    }
  }
}

