import {Injectable, OnDestroy, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { map, publishReplay, refCount, takeUntil } from 'rxjs/operators';

import { CartState } from '../store/states';
import {AuthState} from '../../auth/store/states';

import { createOrder } from '../../core/models/order.model';
import { IVideo } from '../../core/models/video.model';
import { CartItem, createCartItem } from '../../core/models/cart.model';
import * as CartAction from '../store/actions';
import * as CartSelector from '../store/selectors';
import { CreateOrderService } from './create-order.service';
import {UserService} from '../../auth/services/user.service';

import {User} from '../../core/models/user.model';
import {Subject} from 'rxjs';
// import { v4 as uuid } from 'uuid';
import { getAllCartItems } from '../store/selectors';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  shopCartItems: CartItem[];
  user: User;
  ngUnsubscribe$ = new Subject();

  selectItems$ = this.cartStore.select(CartSelector.getAllCartItems).pipe(
    publishReplay(),
    refCount()
  );

  selectTotal$ = this.selectItems$.pipe(
    map(item => item.reduce((acc, cur) => acc + cur.total, 0))
  );

  selectCount$ = this.selectItems$.pipe(map(item => item.length));

  isLoading$ = this.cartStore.select(CartSelector.getLoadingStatus);

  errorMessage$ = this.cartStore.select(CartSelector.getErrorMessage);

  constructor(
    private cartStore: Store<CartState>,
    private authStore: Store<AuthState>,
    private orderService: CreateOrderService,
    private userService: UserService,
  ) {}

  // gc
  addItemToCart(video: IVideo) {
    const id = video.id;
    const videoId = video.id;
    const title = video.title;
    const total = video.price;
    const imageUrl = video.turl;
    const url = video.url;
    const item = createCartItem({ id, videoId, title, total, imageUrl, url});
    this.cartStore.dispatch(new CartAction.AddItemToCart(item));
  }

  removeItem(videoId: CartItem['videoId']) {
    this.cartStore.dispatch(new CartAction.RemoveItemFromCart(videoId));
  }

  createOrder() {
    this.cartStore.select(getAllCartItems)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => this.shopCartItems = data);

    this.user = this.userService.getCurrentUser();
    const order = createOrder(this.shopCartItems, this.user);

    this.orderService.createOrder(order).then(res => {
      /* do something here....maybe clear the form or give a success message */
    });

    for (let i = 0; i < this.shopCartItems.length; i++) {
      const orderItem = {
        id: this.shopCartItems[i].id,
        videoId: this.shopCartItems[i].videoId,
        title: this.shopCartItems[i].title,
        url: this.shopCartItems[i].url,
        turl: this.shopCartItems[i].imageUrl,
        total: this.shopCartItems[i].total,
        email: this.user.email,
        uid: this.user.uid,
        orderNumber: order.orderNumber,
        date: order.date,
      };

      this.orderService.createOrderItems(orderItem).then(res => {
        /*do something here....maybe clear the form or give a success message*/
      });
    }

   // console.log('**** dispatch >> ConfirmOrderSuccess *****');
    this.cartStore.dispatch(new CartAction.ConfirmOrderSuccess());
  }

  resetErrorMessage() {
    this.cartStore.dispatch(new CartAction.ResetErrorMessage());
  }

  removeAll() {
    this.cartStore.dispatch(new CartAction.ResetCart());
  }

  getCartGrandTotal() {
    this.cartStore.select(getAllCartItems)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => this.shopCartItems = data);

    let grandTotal = 0;
    const orderItems = this.shopCartItems.map((item) => {
      grandTotal += item.total;
    });
    return grandTotal.toFixed(2);
  }

}
