import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SignInDialogComponent} from '../dialogs/signin-dialog/signin-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  openAlertDialog() {

    const dialogConfig = new MatDialogConfig();

  //  dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
  //  dialogConfig.width = '280px';
  //  dialogConfig.height = '420px';

    const dialogRef = this.dialog.open(SignInDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('SignInDialogComponent-closed');
    });
  }
}
