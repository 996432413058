export interface CartItem {
  id: number;
  videoId: number;
  title?: string;
  total: number;
  imageUrl: string;
  url: string;
}

export function createCartItem(params: Partial<CartItem>) {
  return {
    total: 0,
    ...params
  } as CartItem;
}
