import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { AuthState } from '../states/auth.state';

export const getIsAuthenticated = (state: AuthState): boolean => state.isAuthenticated;
export const getUser = (state: AuthState): any => state.user;
export const getUserUid = (state: AuthState): any => state.user.uid;

export const getAuthorizationHeader = (state: AuthState) => (state) ? 'Bearer ' + state.user.idToken : null;
export const authorizationHeader = createSelector(getUser, getAuthorizationHeader);

export const selectAuthState: MemoizedSelector<object, AuthState>
  = createFeatureSelector<AuthState>('auth');

// IsAuthenticated
export const selectIsAuthenticated: MemoizedSelector<object, boolean> =
  createSelector(selectAuthState, getIsAuthenticated);

// User
export const selectUser: MemoizedSelector<object, any> =
  createSelector(selectAuthState, getUser);

// User uid
export const selectUserUid: MemoizedSelector<object, string> =
  createSelector(selectAuthState, getUserUid);

export const selectUserEmailId: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  state => state.user.email);

export const getIsSuperUser = (state: AuthState): boolean => state.isSuperUser;
export const selectIsSuperUser: MemoizedSelector<object, boolean> =
  createSelector(selectAuthState, getIsSuperUser);













