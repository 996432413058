import { AuthActions, AuthActionTypes } from '../actions';
import { AuthInitialState, AuthState } from '../states/';

export function authReducers(state = AuthInitialState, action: AuthActions): AuthState {
  // console.log(action.type);
  switch (action.type) {

    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        user: action.user,
        isAuthenticated: true,
      };

    case AuthActionTypes.LOGOFF:
      return {
        ...state,
        ...AuthInitialState,
      };

    case AuthActionTypes.LOGIN_REDIRECT_URL:
      return {
        ...state,
      };

    case AuthActionTypes.GetUserRole:
      return {
        ...state,
        isSuperUser: true,
      };

    default:
      return state;
  }
}






