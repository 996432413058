import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';

import { layoutAdapter, LayoutState } from '../states';
import {selectVideosState} from '../../../videos/store/selectors';
import {VideosState} from '../../../videos/store/states';

/*
  Using @ngrx/entity, after creating an adapter,
  you can take advantage of the function getSelectors.
  This function will return 4 basic selectors.
 */
export const getShowSidenav = (state: LayoutState): boolean => state.showSidenav;

export const selectLayoutState: MemoizedSelector<object, LayoutState> = createFeatureSelector<LayoutState>('layout');

export const selectShowSidenav: MemoizedSelector<object, any> = createSelector(selectLayoutState, getShowSidenav);

// new: 1/4/20
export const getDeviceOSState = (state: LayoutState): string => state.deviceOS;
export const selectDeviceOS: MemoizedSelector<object, string> =
  createSelector(selectLayoutState, getDeviceOSState);











