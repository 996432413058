import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {IVideo} from '../../core/models/video.model';
import * as VideosAction from '../store/actions';
import * as PlayerAction from '../../player/store/actions';

import { VideosState } from '../store/states';
import { CartState } from '../../cart/store/states';
import { getCartIds } from '../../cart/store/selectors';
import { PlayerState } from '../../player/store/states';
import {getLoadingStatus} from '../store/selectors';
import { SearchService } from './search.service';

@Injectable({
  providedIn: 'root'
})
export class VideosService implements OnDestroy {

  ngUnsubscribe$ = new Subject();

  private cartItemsIds: any[];

  constructor(private videosStore: Store<VideosState>,
              private cartStore: Store<CartState>,
              private playerStore: Store<PlayerState>,
              private searchService: SearchService
  ) {}

  // 17/3/20 - for loading spinner
  isLoading$ = this.videosStore.select(getLoadingStatus);

  // onSelectRoute(name: string, selected: boolean) {
  onSelectRoute(shortName: string, selected: boolean) {
    if (selected) {

      const view = shortName.toLowerCase();

      switch (view) {
        case 'recommended':
          this.videosStore.dispatch(new VideosAction.QueryRecommended());
          // console.log('SelectedView - recommended');
          break;
        case 'recents':
          this.videosStore.dispatch(new VideosAction.QueryRecents());
          // console.log('SelectedView - recents');
          break;
        case 'all':
          this.videosStore.dispatch(new VideosAction.LoadAllVideosBegin());
          // console.log('SelectedView - all');
          break;
        // case 'search by bible':
        case 'bible':
          this.videosStore.dispatch(new VideosAction.LoadVideosByBibleBegin());
          // console.log('SelectedView - all sort by verses');
          break;
        // case 'search by tags':
        case 'tags':
          // this.videosStore.dispatch(new VideosAction.LoadAllVideosBegin());
          this.videosStore.dispatch(new VideosAction.LoadTagsBegin());
          // this.videosStore.dispatch(new VideosAction.InitializeSelectedTag()); // 22/5/20
          this.videosStore.dispatch(new VideosAction.LoadAllByTagSortBegin()); // 22/5/20
          // console.log('SelectedView - search by tags');
          break;
        // new: 1/5/20
        // case 'search by source':
        case 'source':
          this.videosStore.dispatch(new VideosAction.LoadSourceBegin());
          this.videosStore.dispatch(new VideosAction.LoadVideosBySourceBegin());
          // console.log('SelectedView - search by source');
          break;

        default:
          this.videosStore.dispatch(new VideosAction.QueryRecommended());
          // console.log('SelectedView - default recommended');
          break;
      }

      this.videosStore.dispatch(new VideosAction.UpdateSelectedView(view));

      //  this.router.navigate(['/home', name.toLowerCase()])
      //    .then(r => console.log(name));
    }
  }

  // change: 17/3/20
  loadInitialVideos$() {
    this.videosStore.dispatch(new VideosAction.QueryRecommended());
  }

  SelectedPlayItem(video) {
    this.playerStore.dispatch(new PlayerAction.SelectedPlayVideo(video));
    // this.videosStore.dispatch(new VideosAction.SelectedVideo(video));
  }

  // update videos state entity item "addedToCart" property to true
  addItemToCart(videoId: IVideo['id']) {
    this.videosStore.dispatch(new VideosAction.UpdateAddedToCart(videoId));
  }

  removeItemFromCart(videoId: IVideo['id']) {
    this.videosStore.dispatch(new VideosAction.UndoAddedToCart(videoId));
  }

  bookmarkItem(videoId: IVideo['id']) {
    this.videosStore.dispatch(new VideosAction.BookmarkVideo(videoId));
  }

  undoBookmarkItem(videoId: IVideo['id']) {
    this.videosStore.dispatch(new VideosAction.UndoBookmarkVideo(videoId));
  }

  removeItemFromEntity(videoId: IVideo['id']) {
    this.videosStore.dispatch(new VideosAction.RemoveVideo(videoId));
  }

  showVideoDesc(videoId: IVideo['id']) {
    this.videosStore.dispatch(new VideosAction.ShowVideoDescription(videoId));
  }

  hideVideoDesc(videoId: IVideo['id']) {
    this.videosStore.dispatch(new VideosAction.HideVideoDescription(videoId));
  }

  fetchCartItems() {
    this.cartStore.select(getCartIds).pipe(
      take(1))
      .subscribe(data => this.cartItemsIds = data);

    const itemIds = this.cartItemsIds;

    if (itemIds.length > 0) {
      for (let i = 0; i < itemIds.length; i++) {
        this.videosStore.dispatch(new VideosAction.UpdateAddedToCart(itemIds[i]));
      }
    }
  }

  // modified: bible search - 14/3/20
  onBookSelection(name: string, selected: boolean) {
    if (selected) {
      this.searchService.LoadVideosByBook();
    }
  }

  // modified: tag search - 26/3/20
  onTagSelected(name: string, selected: boolean) {
    if (selected) {
      this.searchService.LoadVideosByTag();
    }
  }

  // new: source search - 1/5/20
  onSourceSelected(name: string, selected: boolean) {
    if (selected) {
      this.searchService.LoadVideosBySource();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
