import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthenticationProvider } from '../../../auth/services/authentication.provider';

@Component({
  selector: 'app-signin-dialog',
  templateUrl: './signin-dialog.component.html',
  styleUrls: ['./signin-dialog.component.css']
})
export class SignInDialogComponent implements OnInit {

  constructor(
    private authService: AuthenticationProvider,
    public dialogRef: MatDialogRef<SignInDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: '') {}

  ngOnInit() {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  doAction() {
    this.dialogRef.close();
    this.authService.ensureLogin();
  }

}
