// import { OnInit, OnDestroy} from '@angular/core';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {map, switchMap, catchError, withLatestFrom} from 'rxjs/operators';

import * as videosActions from '../../store/actions';

import {LoadEntitiesFoc} from '../actions';

import { FreeVideosState } from '../states';
import { FirestoreDataService } from '../../../videos/services/firestore-data.service';
import { IVideoWithMarkCart } from '../../../core/models/video.model';

@Injectable()
export class VideosEffects {

  constructor(
    private actions$: Actions,
    private videosStore: Store<FreeVideosState>,
    private dataService: FirestoreDataService,
  ) {}

  @Effect()
  LoadEntitiesWithFreeVideos$ = this.actions$.pipe(
    ofType(videosActions.ActionTypes.QUERY_FOC),
    switchMap(() => {
      return this.dataService.getFreeVideos().pipe(
        map((videos: Array<IVideoWithMarkCart>) => new LoadEntitiesFoc(videos)),
        catchError(error =>
          of(new videosActions.LoadEntitiesFocFailure({ error }))
        )
      );
    })
  );
}
