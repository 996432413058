import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';

import { LoginComponent } from './components/login/login.component';
import { routing } from './auth.routing';

import { AuthInterceptor } from './services/auth-interceptor';
import { AuthenticationProvider } from './services/authentication.provider';
import { FirebaseAuthService } from './services/firebase-auth.service';
import { WebFirebaseAuthService} from './services/web/firebase-auth.service';

import { Utils } from './utils/utils';
import { WindowRef } from './utils/windowref.service';

import { MatDialogRef } from '@angular/material/dialog';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button'; // 4/4/20

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        routing,

        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule
    ],
  providers: [
    AuthenticationProvider,
    AngularFireAuth,
    // https://stackoverflow.com/questions/47270324/nullinjectorerror-no-provider-for-matdialogref
    {provide: MatDialogRef, useValue: {}},
    Utils, AuthenticationProvider, WindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    WebFirebaseAuthService,
    {
      provide: FirebaseAuthService,
      useClass: WebFirebaseAuthService
    },
  ],
  declarations: [
    LoginComponent

  ],
  entryComponents: [],
})
export class AuthModule {}
