import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VideosEffects } from './effects';

import { videosReducer } from './reducers';

  @NgModule({
    imports: [
      CommonModule,
      StoreModule.forFeature('videos', videosReducer),
      EffectsModule.forFeature([VideosEffects])
    ],

   providers: [VideosEffects]
})

export class VideosStoreModule {}


