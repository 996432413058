import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom, catchError } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

// import from Order module
import { OrderItem } from '../../../core/models/order.model';
import { OrdersService } from '../../../orders/services/orders.service';

// get current authenticated user
import { AuthState } from '../../../auth/store/states';
import { PlaylistsState } from '../states';
import * as playlistsActions from '../../../playlists/store/actions';

import { FirestoreDataService } from '../../services/firestore-data.service';
import { PlaylistService } from '../../services/playlist.service';
// import { RoleService } from '../../../auth/services/role.service';

import { selectUser } from '../../../auth/store/selectors';

import { List } from '../../../core/models/list.model';

@Injectable()
export class PlaylistStoreEffects {

  private lists: List[];

  constructor(
    private actions$: Actions,
    private store: Store<PlaylistsState>,
    private authStore: Store<AuthState>,
    private afs: AngularFirestore,
    private ordersService: OrdersService,
    private dataService: FirestoreDataService,
    private playlistService: PlaylistService,
  //  private roleService: RoleService
  ) { }


  @Effect()
  getPlaylists$ = this.actions$.pipe(
    ofType(playlistsActions.ActionTypes.GET_PLAYLISTS_BEGIN),
    withLatestFrom(this.authStore.select(selectUser)),
    switchMap(([action, user]) =>
      this.dataService.getPlaylists(user.uid).pipe(
      //  map((result: any) => result.orders),
        map((orders: Array<OrderItem>) => new playlistsActions.GetPlaylistsSuccess(orders)),
        catchError(error => of(new playlistsActions.GetPlaylistsFailure(error))),
      )
    )
  );

  @Effect()
  LoadPlaylistsNames$ = this.actions$.pipe(
    ofType(playlistsActions.ActionTypes.LOAD_PLAYLIST_NAMES_BEGIN),
    switchMap(() => {
      return this.playlistService.getPlaylistNames().pipe(
        map(( data ) => new playlistsActions.LoadPlaylistNamesSuccess(data)),
        catchError(error =>
          of(new playlistsActions.LoadPlaylistNamesFailure({ error }))
        )
      );
    })
  );

/*
  @Effect()
  getPlaylistsNames = this.actions$.pipe(
    ofType(playlistsActions.ActionTypes.LOAD_PLAYLIST_NAMES_BEGIN),
    withLatestFrom(this.authStore.select(selectUser)),
    switchMap(([action, user]) =>
      this.dataService.getPlaylistsNames(user.uid).pipe(
        map((data) => this.playlistService.getFilteredPlaylistNames(data)),
      //  switchMap(lists => new playlistsActions.LoadPlaylistNamesSuccess(lists)),
      //  catchError(error => of(new playlistsActions.LoadPlaylistNamesFailure(error)))
      )
    )
  );
*/

  @Effect()
  GetSelectedPlaylists$ = this.actions$.pipe(
    ofType(playlistsActions.ActionTypes.GET_SELECTED_PLAYLISTS_BEGIN),
    withLatestFrom(this.authStore.select(selectUser)),
    switchMap(([action, user]) =>
      this.dataService.getSelectedPlaylists(user.uid).pipe(
        map((orders: Array<OrderItem>) => new playlistsActions.GetSelectedPlaylistsSuccess(orders)),
        catchError(error => of(new playlistsActions.GetSelectedPlaylistsFailure(error)))
      )
    )
  );

  @Effect()
  loadListsForDelete$ = this.actions$.pipe(
    ofType(playlistsActions.ActionTypes.UPDATE_ORDER_ITEM_LISTS),
    switchMap(() => {
      return this.playlistService
        .getOrderItemListsArray()
        .pipe(
          switchMap(lists => [
            new playlistsActions.UpdateOrderItemListsArray(lists),
          //  new pizzaActions.AnotherAction(pizzas)
          ]),
          catchError(error => of(new playlistsActions.UpdateOrderItemListsFailure(error)))
        );
    })
  );

}






