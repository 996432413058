import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import {cartAdapter, CartState} from '../states';

const {
  selectIds: _selectCartIds,
  selectEntities: _selectCartEntities,
  selectAll: _selectCartAll,
  selectTotal: _selectCartTotal,
} = cartAdapter.getSelectors();

export const getCartState = createFeatureSelector<CartState>(
  'cart'
);

export const getCartIds = createSelector(
  getCartState,
  _selectCartIds
);

export const getCartEntities = createSelector(
  getCartState,
  _selectCartEntities
);

export const getAllCartItems = createSelector(
  getCartState,
  _selectCartAll
);

export const getCartTotalItems = createSelector(
  getCartState,
  _selectCartTotal
);

export const getLoadingStatus = createSelector(
  getCartState,
  state => state.isloading
);

export const getErrorMessage = createSelector(
  getCartState,
  state => state.errorMessage
);

// --------------from-angularspree--------------------------
export const getTotalCartValue = createSelector(
  getCartState,
  _selectCartTotal
);





