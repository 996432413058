import { Action } from '@ngrx/store';
import { OrderItem } from '../../../core/models/order.model';
import { List } from '../../../core/models/list.model';
// import {DocumentChangeAction} from '@angular/fire/firestore';
// import {iterator} from 'rxjs/internal-compatibility';

export enum ActionTypes {
  GET_PLAYLISTS_BEGIN = '[Playlists] Get Playlists Begin',
  GET_PLAYLISTS_SUCCESS = '[Playlists] Get Playlists Success',
  GET_PLAYLISTS_FAILURE = '[Playlists] Get Playlists Failure',

  LOAD_PLAYLIST_NAMES_BEGIN = '[Playlists] Load PlayList Names Begin',
  LOAD_PLAYLIST_NAMES_SUCCESS = '[Playlists] Load PlayList Names Done',
  LOAD_PLAYLIST_NAMES_FAILURE = '[Playlists] Load PlayList Names Failure',

  UPDATE_SELECTED_LISTNAME = '[Playlists] Update Selected ListName',
  GET_SELECTED_PLAYLISTS_BEGIN = '[Playlists] Get Selected PlayLists Begin',
  GET_SELECTED_PLAYLISTS_SUCCESS = '[Playlists] Get Selected PlayLists Success',
  GET_SELECTED_PLAYLISTS_FAILURE = '[Playlists] Get Selected PlayLists Failure',

  ADD_PLAYLIST_NAME_BEGIN = '[Playlists] Add Playlist Name Begin',
  ADD_PLAYLIST_NAME_SUCCESS = '[Playlists] Add Playlist Name Success',

  SELECTED_PLAY_VIDEO = '[Playlists] Selected Video',

  RESET_SELECTED_PLAYLIST_NAME = '[Playlists] Reset Selected Playlist Name',

  ADD_TO_LIST_BEGIN = '[Playlists] add to List Begin',

  UPDATE_ADD_PLAYLIST_NAME = '[Playlists] Update Add Playlist Name',
  UPDATE_NEW_PLAYLIST_NAME = '[Playlists] Update New Playlist Name',
  RESET_ADD_NEW_STATES = '[Playlists] Reset Add New States',
  // ------------------------remove-dialog-states------------------------ //
  UPDATE_ORDER_ITEM_LISTS = '[Playlists] Update OrderItem Lists',
  UPDATE_ORDER_ITEM_LISTS_ARRAY = '[Playlists] Update OrderItem Lists Array',
  UPDATE_ORDER_ITEM_LISTS_FAILURE= '[Playlists] Update OrderItem Lists Failure',
  RESET_DELETE_STATES = '[Playlists] Reset Delete States',
  // ------------------------remove-dialog-states------------------------ //
  UPDATE_LISTS_FOR_DELETE = '[Playlists] Update Lists For Delete',
  REMOVE_VIDEO = '[Playlists] Remove Video',
}

export class GetPlaylistsBegin implements Action {
  readonly type = ActionTypes.GET_PLAYLISTS_BEGIN;
}

export class GetPlaylistsSuccess implements Action {
  readonly type = ActionTypes.GET_PLAYLISTS_SUCCESS;
  constructor(public payload: Array<OrderItem>) {}
}

export class GetPlaylistsFailure implements Action {
  readonly type = ActionTypes.GET_PLAYLISTS_FAILURE;

  constructor(public payload: { error: any }) {}
}

export class AddPlaylistNameBegin implements Action {
  readonly type = ActionTypes.LOAD_PLAYLIST_NAMES_BEGIN;

  constructor(
    public id: string,
    public changes: { lists: string },
  ) { }
}

export class AddPlaylistNameSuccess implements Action {
  readonly type = ActionTypes.ADD_PLAYLIST_NAME_SUCCESS;

  constructor() { }
}

export class LoadPlaylistNamesBegin implements Action {
  readonly type = ActionTypes.LOAD_PLAYLIST_NAMES_BEGIN;
}

export class LoadPlaylistNamesSuccess implements Action {
  readonly type = ActionTypes.LOAD_PLAYLIST_NAMES_SUCCESS;

  constructor(public payload: Array<List>) {}
}

export class LoadPlaylistNamesFailure implements Action {
  readonly type = ActionTypes.LOAD_PLAYLIST_NAMES_FAILURE;

  constructor(public payload: { error: any }) {}
}

export class UpdateSelectedListName implements Action {
  readonly type = ActionTypes.UPDATE_SELECTED_LISTNAME;
  constructor(public payload: string) { }
}

export class GetSelectedPlaylistsBegin implements Action {
  readonly type = ActionTypes.GET_SELECTED_PLAYLISTS_BEGIN;
}

export class GetSelectedPlaylistsSuccess implements Action {
  readonly type = ActionTypes.GET_SELECTED_PLAYLISTS_SUCCESS;

  constructor(public payload: Array<OrderItem>) {}
}

export class GetSelectedPlaylistsFailure implements Action {
  readonly type = ActionTypes.GET_SELECTED_PLAYLISTS_FAILURE;

  constructor(public payload: { error: any }) {}
}

export class SelectedPlayVideo implements Action {
  readonly type = ActionTypes.SELECTED_PLAY_VIDEO;

  constructor(public payload: OrderItem) {}
}

export class ResetSelectedPlaylistName implements Action {
  readonly type = ActionTypes.RESET_SELECTED_PLAYLIST_NAME;
}

// ---------------add-dialog-states---------------- //
export class UpdateAddPlaylistName implements Action {
  readonly type = ActionTypes.UPDATE_ADD_PLAYLIST_NAME;
  constructor(public payload: string[]) { }
}

export class UpdateNewPlaylistName implements Action {
  readonly type = ActionTypes.UPDATE_NEW_PLAYLIST_NAME;
  constructor(public payload: string) { }
}

export class ResetAddNewStates implements Action {
  readonly type = ActionTypes.RESET_ADD_NEW_STATES;
}


// -------------remove-dialog-states-------------- //
export class UpdateOrderItemLists implements Action {
  readonly type = ActionTypes.UPDATE_ORDER_ITEM_LISTS;
  constructor(public payload: string[]) { }
}

export class UpdateOrderItemListsArray implements Action {
  readonly type = ActionTypes.UPDATE_ORDER_ITEM_LISTS_ARRAY;
  constructor(public payload: List[]) { }
}

export class UpdateOrderItemListsFailure implements Action {
  readonly type = ActionTypes.UPDATE_ORDER_ITEM_LISTS_FAILURE;

  constructor(public payload: { error: any }) {}
}

export class UpdateListsForDelete implements Action {
  readonly type = ActionTypes.UPDATE_LISTS_FOR_DELETE;
  constructor(public payload: string[]) { }
}

export class ResetDeleteStates implements Action {
  readonly type = ActionTypes.RESET_DELETE_STATES;
}
// -------------end of remove-dialog-states-------------- //

export class RemoveVideo implements Action {
  readonly type = ActionTypes.REMOVE_VIDEO;

  constructor(public payload: number) {}
}

export type PlaylistsActions
  = GetPlaylistsBegin | GetPlaylistsSuccess | GetPlaylistsFailure
  | AddPlaylistNameBegin | AddPlaylistNameSuccess | LoadPlaylistNamesBegin
  | LoadPlaylistNamesSuccess | LoadPlaylistNamesFailure
  | UpdateSelectedListName
  | GetSelectedPlaylistsBegin | GetSelectedPlaylistsSuccess | GetSelectedPlaylistsFailure
  | SelectedPlayVideo
  | ResetSelectedPlaylistName
  | UpdateAddPlaylistName | UpdateNewPlaylistName | ResetAddNewStates
  | UpdateOrderItemLists | UpdateOrderItemListsArray | UpdateOrderItemListsFailure
  | UpdateListsForDelete | ResetDeleteStates
  | RemoveVideo;


