import {
  Component,
  EventEmitter,
//  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  // ViewChild,
  // AfterViewInit,
  DoCheck, ChangeDetectionStrategy
} from '@angular/core';

import { Subject } from 'rxjs';
import {Store} from '@ngrx/store';
import {VideosState} from '../../store/states';
import {IVideo, IVideoWithMarkCart} from '../../../core/models/video.model';
import {Router} from '@angular/router';
import {CartService} from '../../../cart/services/cart.service';
import { AuthState } from '../../../auth/store/states';
import { VideosService } from '../../services/videos.service';
// import { ScrollToTop } from '../../../videos/services/scroll-to-top';

@Component({
  selector: 'app-videos-list',
  templateUrl: './videos-list.component.html',
  styleUrls: ['./videos-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideosListComponent implements OnInit, DoCheck, OnDestroy {
  @Input() videos$: any[] = [];
  @Input() user$: any[] = [];
  @Input() superUser$: boolean;

  // --------overlay------------
  @Input() overlay: boolean = null;

  @Input() viewSelected$: any; // new: 28/3/20

  private onDestroy$ = new Subject<void>();

  constructor(
    private store$: Store<VideosState>,
    private authStore: Store<AuthState>,
    private router: Router,
    private cartService: CartService,
    private videosService: VideosService,
    ) {
    }

  ngOnInit() {
   this.videosService.loadInitialVideos$();
  }

  ngDoCheck() {
  //  this.videosService.fetchCartItems(); // <<--------------------------<<<<
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // https://blog.angular-university.io/angular2-router/
// <------------------------- onSelect video to play --------------------------->
  onSelect(video: IVideo): void {
    this.videosService.SelectedPlayItem(video);
    this.router.navigate(['/player']);
  }
// <------------------------- onSelect video to play --------------------------->

  // set videos entity state property 'addedToCart' to true when add to cart
  onAddToCart(video: IVideo) {
    this.cartService.addItemToCart(video);
    this.videosService.addItemToCart(video.id);
  }

  // delete from cart & set videos entity state property 'addedToCart' to false
/*
  onDeletefromCart(video: IVideo) {
  this.cartService.removeItem(video.id);
  this.videosService.removeItemFromCart(video.id);
}*/

  // delete this video from videos entity state (option: Not interested. Hide this)
  onRemove(video: IVideo) {
    this.videosService.removeItemFromEntity(video.id);
  }

  // update this video in videos entity state bookmark to true
  onBookmark(video: IVideo) {
    this.videosService.bookmarkItem(video.id);
  }

  // update this video in videos entity state bookmark to false
  onUndoBookmark(video: IVideo) {
    this.videosService.undoBookmarkItem(video.id);
  }

  onShowDesc(video: IVideo) {
    this.videosService.showVideoDesc(video.id);
  }

  onHideDesc(video: IVideo) {
    this.videosService.hideVideoDesc(video.id);
  }

  // ----------------------overlay---------------------------
  getOverlayClass(activeClass: string, inactiveClass: string): any {
    return this.overlay ? activeClass : inactiveClass;
  }
  // -----------------------------------------------------------

}
