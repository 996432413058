import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

// @angular/material modules
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';

// Components
import { VideosComponent } from './containers';
import { VideosListComponent } from './components/videos-list/videos-list.component';
import { SearchBarComponent } from './components/search-bar';
import { VideosRouterlinkComponent } from './components/videos-routerlink/videos-routerlink.component';
// mat-progress-spinner
import { LoadingSpinnerComponent } from './components/loading-spinner';

// Services
import { FirestoreDataService } from './services/firestore-data.service';
import { VideosService } from './services/videos.service';
import { SearchService } from './services/search.service';

import { MatElevationDirective } from './directives/mat-elevation.directive';
import { VideosRoutingModule } from './videos-routing.module';

const COMPONENTS = [
  VideosComponent,
  VideosListComponent,
  MatElevationDirective,
  SearchBarComponent,
  VideosRouterlinkComponent,
  LoadingSpinnerComponent
];

@NgModule({
  declarations: COMPONENTS,
    imports: [
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        // @angular/material modules
        MatCardModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatSelectModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatExpansionModule,
        MatTooltipModule,
        MatChipsModule,
        VideosRoutingModule,
    ],
  exports: COMPONENTS,
  entryComponents: [LoadingSpinnerComponent],
  providers: [
    FirestoreDataService,
    VideosService,
    SearchService
  ]
})
export class VideosModule {}
