// from trivia

import { User } from '../../../core/models/user.model';

// create new store based on EntityState
export interface AuthState {
  user: User | null;
  isAuthenticated?: boolean;
  loginRedirectUrl?: string;
  isSuperUser: boolean;
}

// set the initial store
export const AuthInitialState: AuthState = (
  {
    user: null,
    isAuthenticated: false,
    loginRedirectUrl: null,
    isSuperUser: false,
  }
);

