import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {videosAdapter, FreeVideosState} from '../states';

const {
  selectIds: _selectVideoIds,
  selectEntities: _selectVideoEntities,
  selectAll: _selectAllVideos,
  selectTotal:  videosCount,
} = videosAdapter.getSelectors();

export const selectVideosState = createFeatureSelector<FreeVideosState>('freevideos');

export const selectVideosIds = createSelector(
  selectVideosState,
  _selectVideoIds
);
export const selectVideosEntities = createSelector(
  selectVideosState,
  _selectVideoEntities
);
export const selectFreeVideosAll = createSelector(
  selectVideosState,
  _selectAllVideos
);
export const selectVideosTotal = createSelector(
  selectVideosState,
  videosCount
);

// use for playing video
// ----------------------
/*
export const getSelectedVideo = (state: FreeVideosState) => state.selectedPlayVideo;
export const SelectedPlayVideo: MemoizedSelector<object, any> = createSelector(
  selectVideosState,
  getSelectedVideo);
*/

// use for spinner
export const getLoadingStatus = createSelector(
  selectVideosState,
  state => state.isLoading
);























