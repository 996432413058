import { VideosActions, VideosActionTypes} from '../actions';
import { videosAdapter, initialState, VideosState } from '../states';

export function videosReducer(state = initialState, action: VideosActions): VideosState {
 // console.log(action.type);
  switch (action.type) {

    // new 16/3/20: for storing user navigations in state
    case VideosActionTypes.UPDATE_SELECTED_VIEW:
      return {
        ...state,
        selectedView: action.payload
      };

    case VideosActionTypes.QUERY_RECOMMENDED:
      return {
        ...state,
        isLoading: true,
      };

    case VideosActionTypes.ADD_ALL_RECOMMENDED:
      return videosAdapter.addAll(action.payload, state);

    // case ActionTypes.REMOVE_VIDEO:
    //   return videosAdapter.removeOne(action.payload, homeState);

    case VideosActionTypes.QUERY_RECENTS:
      return {
        ...state,
        isLoading: true,
      };

    case VideosActionTypes.ADD_ALL_RECENTS:
      return videosAdapter.addAll(action.payload, state);

    case VideosActionTypes.LOAD_ALL_VIDEOS_SUCCESS:
      return videosAdapter.addAll(action.payload, state);

    case VideosActionTypes.LOAD_VIDEOS_BY_BIBLE_SUCCESS:
      return videosAdapter.addAll(action.payload, state);

    case VideosActionTypes.LOAD_VIDEOS_BY_TAG_SUCCESS:
      return videosAdapter.addAll(action.payload, state);

    case VideosActionTypes.LOAD_VIDEOS_BY_BOOK_SUCCESS:
      return videosAdapter.addAll(action.payload, state);

    case VideosActionTypes.LOAD_VIDEOS_BY_SOURCE_SUCCESS:
      return videosAdapter.addAll(action.payload, state);

    // new: 28/3/20
    // ---------------------------testing --------------------------------
    case VideosActionTypes.LOAD_ALL_BY_TAG_SORT_SUCCESS:
      return videosAdapter.addAll(action.payload, state);
    // ---------------------------testing --------------------------------


    // new: 26/3/20
    case VideosActionTypes.LOAD_VIDEOS_BY_BOOK_FAILURE:
      return videosAdapter.removeAll(state);

    // new: 26/3/20
    case VideosActionTypes.LOAD_VIDEOS_BY_TAG_FAILURE:
      return videosAdapter.removeAll(state);

    // use for videos title search - 13/3/20
    case VideosActionTypes.LOAD_VIDEOS_BY_ID_SUCCESS:
      return videosAdapter.addAll(action.payload, state);

    case VideosActionTypes.LOAD_ALL_VIDEOS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case VideosActionTypes.LOAD_VIDEOS_BY_TAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case VideosActionTypes.LOAD_VIDEOS_BY_BOOK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case VideosActionTypes.LOAD_TAGS_DONE:
      return {
        ...state,
        isLoading: false,
        error: false,
        tags: action.payload,
      };

    case VideosActionTypes.LOAD_SOURCE_DONE:
      return {
        ...state,
        isLoading: false,
        error: false,
        source: action.payload,
      };

    // new: 1/5/20
    case VideosActionTypes.UPDATE_SELECTED_SOURCE:
      return {
        ...state,
        selectedSource: action.payload
      };

    // use for videos title search - 13/3/20
    case VideosActionTypes.UPDATE_TITLES_STATE_DONE:
      return {
        ...state,
        isLoading: false,
        error: false,
        titles: action.payload,
      };

    case VideosActionTypes.UPDATE_SELECTED_TAG:
      return {
        ...state,
        selectedTag: action.payload
      };

    case VideosActionTypes.UPDATE_SELECTED_BOOK:
      return {
        ...state,
        selectedBook: action.payload
      };

    // use for videos title search - 13/3/20
    case VideosActionTypes.UPDATE_SELECTED_ID:
      return {
        ...state,
        selectedId: action.payload
      };

    case VideosActionTypes.REMOVE_VIDEO:
      return videosAdapter.removeOne(action.payload, state);

    case VideosActionTypes.BOOKMARK_VIDEO:
      const updatedVideo = {
        id: action.payload,
        changes: { isBookmarked: true }
      };
      return videosAdapter.updateOne(updatedVideo, state);

    case VideosActionTypes.UNDO_BOOKMARK_VIDEO:
      const updateVideo = {
        id: action.payload,
        changes: { isBookmarked: false }
      };
      return videosAdapter.updateOne(updateVideo, state);

    case VideosActionTypes.SHOW_VIDEO_DESCRIPTION:
      const show = {
        id: action.payload,
        changes: { isShowDescription: true }
      };
      return videosAdapter.updateOne(show, state);

    case VideosActionTypes.HIDE_VIDEO_DESCRIPTION:
      const hide = {
        id: action.payload,
        changes: { isShowDescription: false }
      };
      return videosAdapter.updateOne(hide, state);

    case VideosActionTypes.UPDATE_ADDED_TO_CART:
      const updateAdd = {
        id: action.payload,
        changes: { isAddedToCart: true }
      };
      return videosAdapter.updateOne(updateAdd, state);

    case VideosActionTypes.UNDO_ADDED_TO_CART:
      const updateUndo = {
        id: action.payload,
        changes: { isAddedToCart: false }
      };
      return videosAdapter.updateOne(updateUndo, state);

    case VideosActionTypes.Initialize_Videos_State:
      return {
        ...state,
        selectedBook: null,
        selectedId: null,
        selectedTag: null,
        selectedSource: null,
        selectedView: null,
        error: null,
      };

    case VideosActionTypes.INITIALIZE_SELECTED_TAG:
      return {
        ...state,
        selectedTag: null,
      };

    default: {
      return state;
    }
  }
}

