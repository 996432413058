import { Action } from '@ngrx/store';
import {IVideo, IVideoWithMarkCart} from '../../../core/models/video.model';

export enum ActionTypes {
  QUERY_FOC = '[Videos] Query Free Videos',
  LOAD_ENTITIES_FOC = '[Videos] Load Entities Free Videos',
  LOAD_ENTITIES_FOC_FAILURE = '[Videos] Load Entities Free Videos Failure',
  SELECTED_PLAY_VIDEO = '[Videos] Selected Play Video',
  REMOVE_VIDEO = '[Videos] Remove Video',
  BOOKMARK_VIDEO = '[Videos] Bookmark Video',
  UNDO_BOOKMARK_VIDEO = '[Videos] Undo Bookmark Video',
  SHOW_VIDEO_DESCRIPTION =  '[Videos] Show Video Description',
  HIDE_VIDEO_DESCRIPTION =  '[Videos] Hide Video Description',
  Initialize_Videos_State = '[Videos] Initialize Videos State',
}

export class QueryFoc implements Action {
  readonly type = ActionTypes.QUERY_FOC;
}

export class LoadEntitiesFoc implements Action {
  readonly type = ActionTypes.LOAD_ENTITIES_FOC;
  constructor(public payload: IVideoWithMarkCart[]) {
  }
}

export class LoadEntitiesFocFailure implements Action {
  readonly type = ActionTypes.LOAD_ENTITIES_FOC_FAILURE;
  constructor(public payload: { error: any }) {}
}

// User selects video to play
export class SelectedPlayVideo implements Action {
  readonly type = ActionTypes.SELECTED_PLAY_VIDEO;
  constructor(public payload: IVideo) {}
}

// --------mat-card-actions section--------------------
// Remove video from state entity
export class RemoveVideo implements Action {
  readonly type = ActionTypes.REMOVE_VIDEO;
  constructor(public payload: number) {}
}

// Bookmark video in state entity
export class BookmarkVideo implements Action {
  readonly type = ActionTypes.BOOKMARK_VIDEO;
  constructor( public payload: number) {}
}

export class UndoBookmarkVideo implements Action {
  readonly type = ActionTypes.UNDO_BOOKMARK_VIDEO;
  constructor( public payload: number ) {}
}

export class ShowVideoDescription implements Action {
  readonly type = ActionTypes.SHOW_VIDEO_DESCRIPTION;
  constructor( public payload: number) {}
}

export class HideVideoDescription implements Action {
  readonly type = ActionTypes.HIDE_VIDEO_DESCRIPTION;
  constructor( public payload: number ) {}
}

export type Actions
  = QueryFoc | LoadEntitiesFoc | LoadEntitiesFocFailure
  | SelectedPlayVideo
  | RemoveVideo
  | BookmarkVideo | UndoBookmarkVideo
  | ShowVideoDescription | HideVideoDescription;





