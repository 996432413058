import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthState } from '../store/states';
import {selectUser, selectUserEmailId} from '../store/selectors';
import { User } from '../../core/models/user.model';
import {map} from 'rxjs/operators';
import * as authActions from '../store/actions';
import {AngularFirestore} from '@angular/fire/firestore';

export interface Role {
  role: string;
  userId: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User;
  UserEmailId: string;

  constructor(
              private authStore: Store<AuthState>,
              private afs: AngularFirestore
  ) { }

  getCurrentUser() {
    this.authStore.select(selectUser)
      .subscribe(res => this.user = (res));
    return (this.user);
  }

  getUserEmailId() {
    this.authStore.select(selectUserEmailId)
      .subscribe(data => this.UserEmailId = (data));
    return (this.UserEmailId);
  }

  getUserRole() {
    const UserEmailId = this.getUserEmailId();
    const rolesRef = this.afs.collection<Role>('roles', ref => {
      return ref.where('userId', '==', UserEmailId)
        .where('isSuperUser', '==', true);
    });
    return rolesRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map( doc => {
          const data = doc.payload.doc.data();
          if (data != null) {
            this.authStore.dispatch(new authActions.GetUserRole());
          }
        });
      }));
  }
}
