import {Injectable, PLATFORM_ID, APP_ID, Inject, OnInit, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import {Observable, defer, throwError, from, of, Subscription} from 'rxjs';
import { share, take, tap, mapTo, map, filter } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

/* *---------------------------for authentication---------------------------**/
import { User } from '../../core/models/user.model';
import { FirebaseAuthService } from './firebase-auth.service';
import { AuthState } from '../store/states/auth.state';
import * as fromAuthSelectors from '../../auth/store/selectors';
import * as fromAuthActions from '../../auth/store/actions';
/* *---------------------------for authentication---------------------------**/

@Injectable()
export class AuthenticationProvider implements OnInit {

  refreshTokenObserver: Observable<any>;
  user: User;
  isAuthenticated: boolean;
  sub: Subscription;
  isLogoff: boolean;
  /* *---------------authentication.provider constructor-----------------**/
  constructor(
    private store: Store<AuthState>,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,
    private firebaseAuthService: FirebaseAuthService) {

    /* *-------------------firebaseAuthService.authState-------------------**/
     // console.log('*******authentication.provider.ts >>> constructor()*******');

      this.firebaseAuthService.authState().subscribe(afUser => {
        if (afUser) {
          this.firebaseAuthService.getIdToken(afUser, false).then((token) => {
            this.user = new User(afUser);
            this.user.idToken = token;
            this.store.dispatch(new fromAuthActions.LoginSuccess(this.user));
          });
        } else {
          // user not logged in
          // console.log('user is not logged in');
          this.store.dispatch(new fromAuthActions.Logoff());
        }
      });

      this.refreshTokenObserver = defer(() => {
      return from(this.generateToken(true));
    }).pipe(share());

  }
  /* ------------------------end of constructor-------------------------**/

  ngOnInit() {
    // get authentication status
    this.store.select(fromAuthSelectors.selectIsAuthenticated).subscribe(
        val => this.isAuthenticated = (val));

   // console.log('user is not logged in');
    this.store.dispatch(new fromAuthActions.Logoff());
  }

  ensureLogin(url?: string): Observable<boolean> {

   // console.log('auth > services > authentication.provider.ts >>> ensureLogin()');

    if (isPlatformBrowser(this.platformId)) {
      if (!this.isAuthenticated) {
        this.showLogin(url);
      }
    } else if (!isPlatformServer(this.platformId) && !isPlatformBrowser(this.platformId)) {
      if (!this.isAuthenticated) {
        this.showLogin(url);
        return of(false);
      }
    }
    /*
    return this.store.select(coreState).pipe(
      map(s => s.user),
      filter(u => (u != null && u.userId !== '')),
      take(1),
      mapTo(true));
    */

    return this.store.select(fromAuthSelectors.selectUser).pipe(
      map(s => s.user),
      filter(u => (u != null && u.userId !== '')),
      take(1),
      mapTo(true));
  }

/*
      map(s => s.user),
      filter(u => (u != null && u.userId !== '')),
      take(1),
      mapTo(true));
*/

  showLogin(url?: string) {
    // this.store.dispatch(this.uiStateActions.setLoginRedirectUrl(url));
    this.store.dispatch(new fromAuthActions.SetLoginRedirectUrl( url));

    this.firebaseAuthService.showLogin();
  }

  logout() {
    this.firebaseAuthService.signOut();
  }

  generateToken(flag) {
    return this.firebaseAuthService.refreshToken(flag).then((token) => {
      return token;
    });
  }

  refreshToken(): Observable<any> {
    return this.refreshTokenObserver.pipe(tap((tokenResponse) => {
          this.user.idToken = tokenResponse;
          // this.store.dispatch(this.userActions.loginSuccessAction(this.user));
          this.store.dispatch(new fromAuthActions.LoginSuccess(this.user));
          return tokenResponse;
        },
        (err) => {
          return throwError(err);
        }));
  }

}
