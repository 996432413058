import { Action } from '@ngrx/store';
import {IVideo, IVideoWithMarkCart} from '../../../core/models/video.model';
import {VideosActionTypes} from '../../../videos/store/actions';

export enum PlayerActionTypes {
  SELECTED_PLAY_VIDEO = '[Videos] Selected Play Video',
  UNDO_SELECTED_VIDEO = '[Videos] Undo Selected Video',
}

export class SelectedPlayVideo implements Action {
  readonly type = PlayerActionTypes.SELECTED_PLAY_VIDEO;
  constructor(public payload: IVideo) {}
}

export class UndoSelectVideo implements Action {
  readonly type = PlayerActionTypes.UNDO_SELECTED_VIDEO;
}

export type PlayerActions
  = SelectedPlayVideo | UndoSelectVideo;

