import { Action } from '@ngrx/store';
import { OrdersState } from '../states';
import { Order } from '../../../core/models/order.model';

export enum OrdersActionTypes {
  QUERY_FIRESTORE_BY_USER = '[Orders] Query Firestore By UserId',
 // QUERY_BY_USER = '[Orders] Query by User',
  ADD_ALL = '[Orders] Add All',
  LOAD_ORDERS_SUCCESS = '[Orders] Load orders success',
  ADD_ORDER = '[Orders] Add order',
  ADD_ORDER_SUCCESS = '[Orders] Add order success',
  REMOVE_ORDER = '[Orders] Remove order',
  REMOVE_ORDER_SUCCESS = '[Orders] Remove order success',
  CREATE_NEW_ORDER = '[Orders] Create New Order',
  RESET_ORDERS = '[Orders] Reset Orders',
  GET_ORDERS_BEGIN = '[Orders] Get Orders begin',
  GET_ORDERS_SUCCESS = '[Orders] Get Orders Success',
  GET_ORDERS_FAILURE = '[Orders] Get Orders Failure',
}

export class QueryFirestoreByUser implements Action {
  readonly type = OrdersActionTypes.QUERY_FIRESTORE_BY_USER;
}

/*
export class QueryByUser implements Action {
  readonly type = OrdersActionTypes.QUERY_BY_USER;
  constructor(public payload: string) {
  }
}
*/

export class AddAll implements Action {
  readonly type = OrdersActionTypes.ADD_ALL;
  constructor(public payload: Order[]) {
  }
}

export class LoadOrdersSuccess implements Action {
  readonly type = OrdersActionTypes.LOAD_ORDERS_SUCCESS;
  constructor(public payload: OrdersState) {}
}

/*
export class AddOrder implements Action {
  readonly type = ActionTypes.ADD_ORDER;
  constructor(public payload: INewOrder) {}
}
*/

export class AddOrderSuccess implements Action {
  readonly type = OrdersActionTypes.ADD_ORDER_SUCCESS;
  constructor(public payload: Order[]) {}
}

export class RemoveOrder implements Action {
  readonly type = OrdersActionTypes.REMOVE_ORDER;
  constructor(public payload: { id: string }) {}
}

export class RemoveOrderSuccess implements Action {
  readonly type = OrdersActionTypes.REMOVE_ORDER_SUCCESS;
  constructor(public payload: { id: string }) {}
}

export class CreateNewOrder implements Action {
  readonly type = OrdersActionTypes.CREATE_NEW_ORDER;
  constructor(public payload: Order) {
  }
}

export class ResetOrders implements Action {
  readonly type = OrdersActionTypes.RESET_ORDERS;
}

export class GetOrdersBegin implements Action {
  readonly type = OrdersActionTypes.GET_ORDERS_BEGIN;
}

export class GetOrdersSuccess implements Action {
  readonly type = OrdersActionTypes.GET_ORDERS_SUCCESS;
  constructor(public payload: Array<Order>) {}
}

export class GetOrdersFail implements Action {
  readonly type = OrdersActionTypes.GET_ORDERS_FAILURE;
  constructor(public payload: any) {}
}

export type OrdersActions =
  QueryFirestoreByUser
 // | QueryByUser
  | AddAll
  | LoadOrdersSuccess
  // | AddOrder
  | AddOrderSuccess
  | RemoveOrder
  | RemoveOrderSuccess
  | CreateNewOrder
  | ResetOrders
  | GetOrdersBegin
  | GetOrdersSuccess
  | GetOrdersFail;
