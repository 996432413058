import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

import { IVideo } from '../../core/models/video.model';
// import {Keyword} from '../../core/models/keyword.model';
import {Book} from '../../core/models/book.model';
// use for title search
import {Title} from '../../core/models/title.interface';
import {Tag} from '../../core/models/tag.interface';

@Injectable({
  providedIn: 'root'
})
export class FirestoreDataService {

  constructor(
    private actions$: Actions,
    private afs: AngularFirestore
  ) {
  }

  // -------------------------------Get Videos------------------------------- //
  getRecommended() {
    const videosRef = this.afs.collection<IVideo>('videos', ref => {
      return ref.where('reco', '==', true)
        .orderBy('title', 'asc');
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          // console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }

  getRecents() {
    const videosRef = this.afs.collection<IVideo>('videos', ref => {
      return ref.where('rece', '==', true);
      // .orderBy('title', 'asc'); // GC/3/3/20 - this line of code causes error
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          // console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }

  getAllVideos() {
    const videosRef = this.afs.collection<IVideo>('videos', ref => {
      return ref.orderBy('title', 'asc');
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
         // console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }

  // modified: title search - 14/3/20
  getVideoById(videoId) {
    const videosRef = this.afs.collection<IVideo>('videos', ref => {
      return ref.where('id', '==', videoId);
      // .orderBy('title', 'asc');
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          // console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }

  getAllVideosByBible() {
    const videosRef = this.afs.collection<IVideo>('videos', ref => {
      // return ref.orderBy('bsort', 'asc').orderBy('vsort', 'asc');
      return ref.orderBy('vid', 'asc');
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          // console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }

  getVideosByBook(book) {
    console.log(book);
    const videosRef = this.afs.collection<IVideo>('videos', ref => {
      // return ref.where('book', '==', book).orderBy('vsort', 'asc');
      return ref.where('book', '==', book);
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          //  console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }

  getFreeVideos() {
    const videosRef = this.afs.collection<IVideo>('videos-foc', ref => {
      return ref.orderBy('title', 'asc');
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          // console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }


// --------------------------------- tags start --------------------------------------
  getTags() {
    const TagsRef = this.afs.collection<Tag>('tags', ref => {
      return ref.orderBy('name', 'asc');
    });
    return TagsRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          //  console.log(data);
          // return {id: doc.payload.doc.id, ...data};
          return { ...data};
        });
      }));
  }

  getVideosTagsSort() {
    const videosRef = this.afs.collection<IVideo>('videos', ref => {
      return ref.orderBy('tags', 'asc');
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          // console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }

  getVideosByTag(tag) {
    // const searchTag = tag.trim().toLowerCase();
    const searchTag = tag.trim(); // 28/3/20

    const videosRef = this.afs.collection<IVideo>('videos', ref => {
     // return ref.where('tags', 'array-contains', searchBy).orderBy('title', 'asc');
      return ref.where('tags', 'array-contains', searchTag);
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          //  console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }

  // ---------------------------- tags end ----------------------------------

  // ------------------------Get Lists & Mat-chips---------------------------- //

  // modified: title search - 14/3/20
  getTitlesForAutocomplete() {
    const titlesRef = this.afs.collection<Title>('videos', ref => {
      return ref.orderBy('title', 'asc');
    });
    return titlesRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const id = doc.payload.doc.data().id;
          const title = doc.payload.doc.data().title;
          const subtitle = doc.payload.doc.data().subtitle;
          // console.log(data);
          // return {id: doc.payload.doc.id, ...data};
          return {id, title, subtitle};
        });
      }));
  }

  // new: source search - 1/5/20
  getSource() {
    const SourceRef = this.afs.collection<Tag>('source', ref => {
      return ref.orderBy('name', 'asc');
    });
    return SourceRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          //  console.log(data);
          // return {id: doc.payload.doc.id, ...data};
          return { ...data};
        });
      }));
  }

  // new: source search - 1/5/20
  getVideosBySource(source) {
    // const searchTag = tag.trim().toLowerCase();
    const searchSource = source.trim(); // 28/3/20

    const videosRef = this.afs.collection<IVideo>('videos', ref => {
      return ref.where('source', '==', searchSource)
      .orderBy('title', 'asc');
    });

    return videosRef.snapshotChanges().pipe(
      map(arr => {
        return arr.map(doc => {
          const data = doc.payload.doc.data();
          //  console.log(data);
          return {id: doc.payload.doc.id, ...data};
        });
      }));
  }


}




