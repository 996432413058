import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AuthStoreModule } from '../auth/store';
import { AppLayoutStoreModule } from '../app-layout/store';
import { OrdersStoreModule } from '../orders/store';
import { PlaylistsStoreModule } from '../playlists/store';
import { CartStoreModule } from '../cart/store';
import { VideosStoreModule } from '../videos/store';
import { PlayerStoreModule } from '../player/store';
import { FreeVideosStoreModule } from '../videos-free/store';

@NgModule({
  imports: [
    CommonModule,
    AuthStoreModule,
    AppLayoutStoreModule,
    OrdersStoreModule,
    PlaylistsStoreModule,
    CartStoreModule,
    VideosStoreModule,
    PlayerStoreModule,
    FreeVideosStoreModule,

    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 store
    }),
  ],
  declarations: []
})

export class RootStoreModule {}








