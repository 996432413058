import {
  Component,
 // Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
 // ViewEncapsulation,
  ChangeDetectionStrategy,
 // AfterViewInit
} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { VideosState } from '../store/states';
import { IVideo } from '../../core/models/video.model';
import { AuthState } from '../../auth/store/states';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  selectUser,
  selectIsSuperUser
} from '../../auth/store/selectors';
import {
  getSearchStatus,
  selectVideosAll,
  selectTitles,
  getTagsList,
  getSourceList,
  selectedView
} from '../store/selectors';

import {VideosService} from '../services/videos.service';

// mat-progress-spinner
import { LoadingSpinnerComponent } from '../components/loading-spinner';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideosComponent implements OnInit, OnDestroy {
  // ---------------------------------testing--------------------------------------------
  User$ = this.authStore.select(selectUser);
  public videos$: Observable<any> = this.videosStore.select(selectVideosAll);
  public isSuperUser$: Observable<boolean> = this.videosStore.select(selectIsSuperUser);
  public Tags$: Observable<any> = this.videosStore.select(getTagsList);
  // new: 1/5/20
  public Sources$: Observable<any> = this.videosStore.select(getSourceList);
  public Titles$: Observable<any> = this.videosStore.select(selectTitles);
  public selectedView$: Observable<string> = this.videosStore.select(selectedView);

// -----------------------------------------------------------------------------
  @Output() Select: EventEmitter<IVideo> = new EventEmitter<IVideo>();

  loadingDialogRef: MatDialogRef<LoadingSpinnerComponent>;
  ngUnsubscribe$ = new Subject();

  constructor(private videosStore: Store<VideosState>,
              private authStore: Store<AuthState>,
              private videosService: VideosService,
              private dialog: MatDialog
              ) { }

  ngOnInit() {
    this.videosService.isLoading$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(isLoading => {
        if (isLoading) {
          this.loadingDialogRef = this.dialog.open(LoadingSpinnerComponent);
        } else {
          if (this.loadingDialogRef) {
            this.loadingDialogRef.close();
          }
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}



