import {Component, OnInit, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { Renderer2 } from '@angular/core';

import {InitializeVideosState,
  QueryRecommended,
  LoadAllVideosBegin
} from './videos/store/actions';

// import * as FreevideosActions from './videos/store/actions';
import {LayoutState} from './app-layout/store/states';
/*------------------------rwa login---------------------------*/
import { Utils, WindowRef } from './auth/utils';
// for Authentication
import { AuthenticationProvider } from './auth/services/authentication.provider';
/*------------------------rwa login---------------------------*/
// for displaying the displayName of authenticated user
import { User } from './core/models/user.model';
import { selectUser } from './auth/store/selectors';
import { AuthState} from './auth/store/states';

// from ng-shopping-cart-master
// import { AuthService } from './auth/services/auth.services';
import { take, takeUntil} from 'rxjs/operators';
import { DialogService } from './app-layout/services/dialog.service';
import * as fromLayoutActions from './app-layout/store/actions';
import {selectShowSidenav} from './app-layout/store/selectors';
import {UserService} from './auth/services/user.service';
import {CartService} from './cart/services/cart.service';
// import {CartState} from './cart/store/states';
import {VideosState} from './videos/store/states';
import {DeviceDetectorService} from 'ngx-device-detector';

import {QueryFoc} from './videos-free/store/actions';
import {FreeVideosState} from './videos-free/store/states';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnInit, OnDestroy {
  /*-----------------------------rwa login--------------------------------*/
  sub: Subscription;
  user: User;
  currentUser: User;
  /*-----------------------------rwa login--------------------------------*/
  user$ = this.authStore.select(selectUser);
  showSidenav$: Observable<boolean>;
  cartItemsCount$ = this.cartService.selectCount$;
  ngUnsubscribe$ = new Subject();

  private deviceOS: string = null; // 31/3/20

  constructor(
    public router: Router,
    private authStore: Store<AuthState>,
    private layoutStore: Store<LayoutState>,
    private videosStore: Store<VideosState>,
    private freeVideosStore: Store<FreeVideosState>,
    private renderer: Renderer2,
    /*------------------------rwa authentication---------------------------*/
    private authService: AuthenticationProvider,
    /*------------------------rwa authentication---------------------------*/
    private windowRef: WindowRef,
    private utils: Utils,
    private cartService: CartService,
    private userService: UserService,
    private dialogService: DialogService,
    private deviceService: DeviceDetectorService, // 31/3/20
    private appStore: Store<LayoutState>,
  ) {
    this.showSidenav$ = this.layoutStore.pipe(select(selectShowSidenav));
  }

  ngOnInit() {
    this.deviceOS = this.deviceService.os;
    this.appStore.dispatch(new fromLayoutActions.UpdateDeviceOS(this.deviceOS));
    // console.log(this.deviceOS);
  }

  login() {
    this.authService.ensureLogin(); // trivia
  }

  logout() {
    this.authService.logout(); // trivia
    // this.closeSidenav(); // example-app
  }

  openHome() {
    this.router.navigateByUrl('/home');
  }

  openVideos() {
    // added: 20/3/20
    this.router.navigateByUrl('/videos');
    //  this.videosService.fetchCartItems(); // bug - to redesign

    // change: 17/3/20 - load recommended first
    // this.videosStore.dispatch(new LoadAllVideosBegin());
    this.videosStore.dispatch(new QueryRecommended());
  }

  openPlaylists() {
    this.authStore.select(selectUser).pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => this.currentUser = data);

    // ------------------------- Alert Dialog -----------------------------
    if (this.currentUser == null) {
      console.log('user not logged on!');
      // place a dialog here
      this.dialogService.openAlertDialog();
      return this.router.navigateByUrl('/videos');
    } else {
      this.router.navigateByUrl('/playlists');
    }
  }

  openFreeVideos() {
    // added: 16/5/20
    this.router.navigateByUrl('/freevideos');
    this.freeVideosStore.dispatch(new QueryFoc());

    // added: 15/5/20
    // this.videosStore.dispatch(new InitializeVideosState());
  }

  closeSidenav() {
    this.layoutStore.dispatch(new fromLayoutActions.CloseSidenav());
  }

  // gc
  openSettingDialog() {
    console.log('<<<<<<<<<<<openSettingDialog>>>>>>>>>>>>>');
  }

  // gc
  openHelpPage() {
    console.log('<<<<<<<<<<<openHelpPage>>>>>>>>>>>>>');
  }


  gotoActev8() {
  }

  // ---------------------------- header btn----------------------------- //
  openSidenav() {
    this.layoutStore.dispatch(new fromLayoutActions.OpenSidenav());
  }

  // gc
  openUserDetails() {
    this.userService.getUserRole().pipe(take(1)).subscribe();
  }

  cartClick() {
    this.router.navigate(['cart']);
  }

  ngOnDestroy() {
    this.utils.unsubscribe([this.sub]);
  }

}
