// from rwa-trivia

import * as firebase from 'firebase/app';

export class User {
  uid?: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  id?: string;
  user: string;
  name?: string;
  idToken?: string;
  authState: firebase.User;

  constructor(authState?: firebase.User & { name: string }) {
    if (authState) {
      console.log('core > models > user.model');
      this.authState = authState;
      this.uid = authState.uid;
      this.email = authState.providerData ? authState.providerData[0].email : authState.email;
      this.photoURL = authState.providerData ? authState.providerData[0].photoURL : authState.photoURL;

      if (authState.providerData && authState.providerData[0].displayName) {
        this.displayName = authState.providerData[0].displayName;
      } else if (authState.name) {
        this.displayName = authState.name;
      } else {
        // this.displayName = this.email.split('@')[0] + new Date().getTime();
        this.displayName = this.email.split('@')[0];
      }
    }
  }
}

